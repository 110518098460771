<template>
	<a-row :class="$style.topbar" type="flex" justify="space-between" v-if="theApp" class="top-bar--fixed"
		style="box-shadow: 0px 8px 28px rgba(100, 100, 100, 0.1); background: white;">
		<a-col class="dF f1">
			<router-link to="/select" v-if="$bhapp === 'admin'" class="dF">
				<img alt="Bildhive" class="d-none d-md-block" :src="$env.env === 'prod'
					? require('@/assets/logo.svg')
					: require('@/assets/staging-logo.png')
					" :style="$env.env === 'prod'
						? { width: '130px', 'max-height': '40px' }
						: { 'max-height': '40px' }
						" />
				<img alt="Bildhive" class="d-block d-md-none" :src="$env.env === 'prod'
					? require('@/assets/mobile-logo.png')
					: require('@/assets/staging-mobile-logo.png')
					" style="max-height: 40px;
					" />
				<div class="f1 fw-300 dF aC instance-name-box" style="margin-top: 5px" v-if="instance && instance.name">
					<span class="text-lg text-black ml-4 block lXT">
						{{
							instance.readableId ? instance.readableId + ' - ' : ''
						}}
						{{
							instance.name
						}}
					</span>
				</div>
			</router-link>
			<router-link to="/" v-else-if="$bhapp === 'superadmin'" class="dF">
				<img alt="Bildhive" class="d-none d-md-block" :src="$env.env === 'prod'
					? require('@/assets/logo.svg')
					: require('@/assets/staging-logo.png')
					" :style="$env.env === 'prod'
						? { width: '130px', 'max-height': '40px' }
						: { 'max-height': '40px' }
						" />
				<img alt="Bildhive" class="d-block d-md-none" :src="$env.env === 'prod'
					? require('@/assets/mobile-logo.png')
					: require('@/assets/staging-mobile-logo.png')
					" style="max-height: 40px;
					" />
			</router-link>
			<a :href="`https://admin.bildhive.${$tld}/apps`" class="dF aC" v-else>
				<img alt="Bildhive" class="d-none d-md-block" :src="$env.env === 'prod'
					? require('@/assets/logo.svg')
					: require('@/assets/staging-logo.png')
					" :style="$env.env === 'prod'
						? { width: '130px', 'max-height': '40px' }
						: { 'max-height': '40px' }
						" />
				<img alt="Bildhive" class="d-block d-md-none" :src="$env.env === 'prod'
					? require('@/assets/mobile-logo.png')
					: require('@/assets/staging-mobile-logo.png')
					" style="max-height: 40px;
					" />
				<div class="f1 fw-300 instance-name-box" style="margin-top: 5px" v-if="instance && instance.name">
					<span class="text-lg text-black ml-4 block lXT">
						{{
							instance.readableId ? instance.readableId + ' - ' : ''
						}}
						{{
							instance.name
						}}
					</span>
				</div>
			</a>
			<div v-if="instance?.id" class="ml-3 dF" style="margin-top: 5px;">
				<ProjectSwitcher />
			</div>
		</a-col>

		<a-col style="display: flex; align-items: center">
			<template>
				<div style="display: flex; justify-content: flex-end" class="aC">
					<a-tooltip v-if="theApp && theApp.subdomain && instance && instance.name"
						overlayClassName="change-tooltip-color" placement="bottomRight" title="Switch App"
						:mouseEnterDelay="1">
						<a-avatar @click="drawerBoolean = true" shape="circle" size="large" icon="appstore"
							class="text-gray-6 bordered cursor-pointer instance-apps-trigger rotated mr-3">
						</a-avatar>
					</a-tooltip>

					<div @click="openHelpWidget" class="badge-offer mr-3 d-none d-md-block">
						<a-icon type="question-circle" />&nbsp;&nbsp;&nbsp;Help
					</div>

					<a-drawer v-if="theApp && theApp.subdomain && instance && instance.name" :title="instance.name"
						placement="right" :width="320" @close="drawerBoolean = false"
						:bodyStyle="{ overflow: 'auto', padding: '20px' }" :visible="drawerBoolean">
						<div class="text-center lT">
							<div v-for="(cat, catI) in appsGrouped" :key="`appCat${cat.name}`">
								<div class="text-left mb-3" :class="catI === 0 ? '' : 'mt-5'"
									v-if="cat.name !== 'default'">
									{{ cat.name }}
								</div>
								<div class="active-apps-grid">
									<div v-for="app in cat.apps" :key="app.name" :style="app.subdomain === theApp.subdomain
										? 'cursor: not-allowed; filter: grayscale(1);'
										: ''
										">
										<a :href="`https://${app.subdomain}.bildhive.${$tld}`" :style="app.subdomain ===
											theApp.subdomain
											? {
												'pointer-events':
													'none',
											}
											: {}
											">
											<img alt="" :src="getAppIcon(app.media)"
												style=" width: 60px; height: 60px; object-fit: contain;" />
											<small class="block mt-2 text-med-gray">{{ app.name }}</small>
										</a>
									</div>
								</div>
							</div>
						</div>
						<div class="mt-8">
							<hr />
							<div>
								<a-button :href="`https://admin.bildhive.${$tld}/apps?marketplace=bh`" type="primary"
									class="w-full">BACK TO WORKPLACE</a-button>
							</div>
						</div>
					</a-drawer>
				</div>
				<div class="mr-4 d-none d-xl-block dash-divider" />
			</template>
			<div style="display: flex; justify-content: flex-end" class="mr-4 d-none d-md-block">
				<air-actions v-if="!$route.meta.redirectAuth && !$route.meta.redirectAuthTermsPrivacy" />
			</div>

			<div style="display: flex; justify-content: flex-end">
				<air-user-menu v-if="!$route.meta.redirectAuth && !$route.meta.redirectAuthTermsPrivacy" />
			</div>
		</a-col>
	</a-row>
</template>

<script>
import AirActions from "bh-mod/components/layout/TopBar/Actions";
import AirUserMenu from "bh-mod/components/layout/TopBar/UserMenu";
import ProjectSwitcher from "bh-mod/components/layout/TopBar/ProjectSwitcher";
import { mapState } from "vuex";
import { getInitial, getAppIcon } from "bh-mod";

import _ from "lodash";

export default {
	components: {
		AirActions,
		AirUserMenu,
		ProjectSwitcher,
	},
	data() {
		return {
			drawerBoolean: false,
		};
	},
	computed: {
		...mapState(["settings"]),
		theApp() {
			return this.$store.state.theApp || {};
		},
		instance() {
			return this.$store.state.instance || {};
		},
		user() {
			return this.$store.state.user && this.$store.state.user.user || null;
		},
		apps() {
			return this.instance.apps;
		},
		appsGrouped() {
			let apps = this.apps || [];
			let cats = {};

			apps.forEach((app) => {
				let cat = app.media.category || "Apps";
				if (!cats[cat]) cats[cat] = { name: cat, apps: [] };
				cats[cat].apps.push(app);
			});

			// Sort category apps based on name
			for (const key in cats) {
				if (Object.hasOwnProperty.call(cats, key)) {
					const category = cats[key];

					category.apps = category.apps.sort((a, b) => a.name < b.name ? -1 : a.name > b.name ? 1 : 0);
					cats[key] = category
				}
			}

			let ordered = [];

			if (cats["default"]) ordered.push(cats.default);
			if (cats["Administration & Team Management"])
				ordered.push(cats["Administration & Team Management"]);
			if (cats["Project Setup & Configuration"])
				ordered.push(cats["Project Setup & Configuration"]);
			if (cats["Project Configuration"])
				ordered.push(cats["Project Configuration"]);
			if (cats["Product Management"])
				ordered.push(cats["Product Management"]);
			if (cats["Marketing & Communications Management"])
				ordered.push(cats["Marketing & Communications Management"]);
			if (cats["Customer & Transaction Management"])
				ordered.push(cats["Customer & Transaction Management"]);

			return ordered;
		},
		currentApp() {
			return this.$store.state.theApp && this.$store.state.theApp.subdomain || 'admin'
		}
	},

	methods: {
		getInitial,
		getAppIcon,
		onClose() { },
		toggleMenu() {
			const setting = "isMenuCollapsed";
			const value = !this.settings[setting];
			this.$store.commit("CHANGE_SETTING", { setting, value });
		},

		openHelpWidget() {
			if (FreshworksWidget) {
				FreshworksWidget('open');
				// Add custom link for search results to redirect to support portal if search results are more than 10
				this.$nextTick(() => {
					this.addSupportAppLink();
				});
			}
		},

		addSupportAppLink() {
			// Wait for the iframe to load and detect search results
			const checkIframe = setInterval(() => {
				const iframe = document.getElementById("widget-frame"); // Get iframe
				if (iframe?.contentDocument) {
					const iframeDoc = iframe.contentDocument || iframe.contentWindow.document;

					// Start observing for search results inside the iframe
					const observer = new MutationObserver(() => {
						const searchResultTitle = iframeDoc.querySelector('[data-testid="article-search-listtitle"]'); // Get search result title
						const searchResultText = searchResultTitle?.textContent;
						const searchInput = iframeDoc.querySelector('[data-testid="search-input"]'); // Get search term input
						const fullSearchLink = iframeDoc.querySelector(".full-search-link"); // Get the full search link

						// If search input and results exist and link is not added yet then add the link
						if (searchResultText?.includes('10 articles') && searchInput && !fullSearchLink) {
							const searchTerm = encodeURIComponent(searchInput.value.trim()); // Get and encode search term

							// Create a container for info text and link
							const container = document.createElement("div");
							container.classList.add("full-search-link");
							container.style.paddingTop = "10px";
							container.style.fontSize = "15px";

							container.appendChild(document.createTextNode("Displaying up to 10 articles for your search. "));

							// Create the "Click here" link
							const fullSearchLink = document.createElement("a");
							fullSearchLink.href = `https://support.bildhive.com/en/support/search/solutions?term=${searchTerm}`;
							fullSearchLink.textContent = "Click here";
							fullSearchLink.target = "_blank";
							fullSearchLink.style.color = "#f79131";
							fullSearchLink.style.textDecoration = "underline";
							fullSearchLink.style.cursor = "pointer";

							container.appendChild(fullSearchLink);
							container.appendChild(document.createTextNode(" for full search result list."));

							// Insert the container after the '[data-testid="article-search-listtitle"]' element
							searchResultTitle.parentNode.insertBefore(container, searchResultTitle.nextSibling);
						} else if (!searchResultText?.includes('10 articles') && fullSearchLink) {
							// Remove the link if search results are less than 10
							fullSearchLink.remove();
						}
					});

					// Start observing changes in the iframe
					observer.observe(iframeDoc.body, { childList: true, subtree: true });

					// Stop checking once we start observing
					clearInterval(checkIframe);
				}
			}, 1000); // Check every 1 second until iframe loads
		},
	},

	mounted() {
		if (FreshworksWidget) {
			FreshworksWidget('hide', 'launcher');

			FreshworksWidget('identify', 'ticketForm', {
				name: (this.user && (this.user.firstName + ' ' + this.user.lastName)) || '',
				email: (this.user && this.user.email) || '',
				custom_fields: {
					cf_project_id: (this.instance && (this.instance.readableId ? (this.instance.readableId + ' - ' + this.instance.name) : this.instance.name)) || ''
				},
			});
		}
	},
};
</script>

<style lang="scss" module>
@import "./style.module.scss";
</style>

<style lang="scss">
.change-tooltip-color .ant-tooltip-content .ant-tooltip-inner {
	background-color: #3f3356;
}

.active-apps-grid {
	display: grid;
	grid-template-columns: 1fr 1fr;
	gap: 28px;
}

.instance-apps-trigger {
	position: relative;
	background: white;

	[aria-label*="icon"] {
		position: absolute;
		left: 50%;
		top: 50%;
		transform: translate3d(-50%, -50%, 0);
		transition: transform 0.3s ease-out;
		font-size: 20px;
	}

	&:not(:hover) {

		.instance-name,
		[aria-label*="icon"] {
			transition-delay: 0.2s;
		}
	}

	&.rotated:hover {
		[aria-label*="icon"] {
			transform: translate3d(-50%, -50%, 0) rotate(0.25turn);
		}
	}
}

@media screen and (max-width: 700px) {
	.instance-name-box {
		display: none !important;
	}
}

@media screen and (min-width: 700px) {
	.instance-name-box {
		display: flex;
	}
}

.certain-category-search-dropdown .ant-select-dropdown-menu-item-group-title {
	color: #666;
	font-weight: bold;
}

.certain-category-search-dropdown .ant-select-dropdown-menu-item-group {
	border-bottom: 1px solid #f6f6f6;
}

.certain-category-search-dropdown .ant-select-dropdown-menu-item {
	padding-left: 16px;
}

.certain-category-search-dropdown .ant-select-dropdown-menu-item.show-all {
	text-align: center;
	cursor: default;
}

.certain-category-search-dropdown .ant-select-dropdown-menu {
	max-height: 400px;
}
</style>

<style lang="scss" scoped>
.certain-category-search-wrapper>>>.certain-category-search.ant-select-auto-complete .ant-input-affix-wrapper .ant-input-suffix {
	right: 12px;
}

.certain-category-search-wrapper>>>.certain-search-item-count {
	position: absolute;
	color: #999;
	right: 16px;
}

.certain-category-search-wrapper>>>.certain-category-search.ant-select-focused .certain-category-icon {
	color: var(--primary);
}

.certain-category-search-wrapper>>>.certain-category-icon {
	color: #6e6e6e;
	transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
	font-size: 16px;
}

.badge-offer {
	padding: .35rem .65rem;
	text-align: center;
	display: inline-block;
	border-radius: 30px 8px 30px 30px;
	background: #aaa;
	color: #fff;
	font-size: medium;
	transition: all 0.1s ease-out 0s
}

.badge-offer:hover {
	background: var(--primary);
	box-shadow: rgba(0, 0, 0, 0.2) 0px 2px 8px;
	cursor: pointer;
	font-weight: 600;
}
</style>
