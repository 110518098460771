import { render, staticRenderFns } from "./contactModal.vue?vue&type=template&id=056b8a58&scoped=true"
import script from "./contactModal.vue?vue&type=script&lang=js"
export * from "./contactModal.vue?vue&type=script&lang=js"
import style0 from "./contactModal.vue?vue&type=style&index=0&id=056b8a58&prod&lang=scss"
import style1 from "./contactModal.vue?vue&type=style&index=1&id=056b8a58&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "056b8a58",
  null
  
)

export default component.exports