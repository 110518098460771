<template>
	<div>
		<file-upload @newFiles="getUploadedFiles" :fileTypes="fileTypes || ''" :selectedFolder="currentFolder" />

		<a-modal :zIndex="1002" centered title="File Preview" :visible="previewFile.show" :footer="null"
			@cancel="() => previewFile.show = false" :width="'70%'" class="file-selector-modal">
			<img v-if="previewFile.type === 'image'" alt="example"
				style="width: 100%; max-height:80vh; object-fit:contain" :src="previewFile.url" />
			<video v-if="previewFile.type === 'video'" style="width:100%;" controls>
				<source :src="previewFile.url" type="video/mp4">
				Your browser does not support the video tag.
			</video>
		</a-modal>

		<a-modal :visible="link" centered :footer="null" :width="600" :zIndex="1002" @cancel="link = !link">
			<h4>File Link</h4>
			<a-input readOnly v-model="shareLink" id="myInput">
				<a-tooltip slot="prefix" title="Copy link to Clipboard">
					<a-icon @click="copyLink" type="link" />
				</a-tooltip>
			</a-input>

			<div class="mt-3" style="text-align:end">
				<a-button size="large" type="primary" @click="link = !link">DONE</a-button>
			</div>
		</a-modal>

		<a-modal :zIndex="1001" centered :title="'Media Library'" :visible="showMedia && filesLoaded" :footer="null"
			@cancel="onClose" :width="'70%'" class="file-selector-box">
			<div class="dF">
				<template v-if="Object.keys(files).length">
					<!-- Left side of File Manager -->
					<div class="removeScroll"
						style="height: 475px; padding-right: 15px; width: 200px; overflow-y: scroll;">
						<h6 @click="changeCurrentFolder('__all__')" class="cursor-pointer">All Media</h6>
						<a-tree :tree-data="folders" :auto-expand-parent="false" :replaceFields="{
							children: 'children', title: 'title', key: 'key'
						}" blockNode style="font-size: 1rem">
							<template #title="{ key: treeKey, title, expanded }">
								<div class="tree-folder appCategory" @click="changeCurrentFolder(treeKey)">
									<a-icon :type="expanded ? 'folder-open' : 'folder'" theme="filled"
										style="color:#3f3455" />
									<a-tooltip overlayClassName="change-tooltip-color" placement="right">
										<template slot="title">
											{{ title }}
										</template>
										<span class="ml-2">{{ title }}</span>
									</a-tooltip>
								</div>
							</template>
						</a-tree>
					</div>

					<a-row :gutter="16" style="flex: 1;">
						<!-- Right side of File Manager -->
						<a-col :span="24">
							<!-- Top bar options -->
							<a-row :gutter="16" class="mb-4">
								<a-col :span="5">
									<a-select v-model="filter" size="large" default-value="all" style="width: 100%;"
										:disabled="mediaSelect.type !== 'all'">
										<a-select-option value="all">
											All Media Items
										</a-select-option>
										<a-select-option value="images">
											Images
										</a-select-option>
										<a-select-option value="videos">
											Videos
										</a-select-option>
										<a-select-option value="pdf">
											Documents
										</a-select-option>
										<a-select-option value="unsorted">
											Unsorted
										</a-select-option>
									</a-select>
								</a-col>

								<a-col :span="7" class="dF">
									<div style="margin-top: 9px;">Sort By</div>
									<a-select v-model="sortBy" size="large" default-value="latest" class="ml-2 f1">
										<a-select-option v-for="sortOption in sortOptionList" :key="sortOption.value"
											:value="sortOption.value">
											{{ sortOption.name }}
										</a-select-option>
									</a-select>
								</a-col>

								<a-col :span="12" class="dF">
									<div style="display:flex;justify-content:flex-end; width: 100%;">
										<a-auto-complete size="large" placeholder="Search for a file..."
											style="width: 50%; flex: 1;" :dropdown-match-select-width="false"
											:dropdown-style="{ width: '300px' }" option-label-prop="value"
											@search="handleSearch">
											<template slot="dataSource">
												<a-select-option @click="optionClicked(file.id, file.folder)"
													v-for="(file) in searchResult" :key="file.id"
													:value="`${file.folderPath === '/' ? '' : file.folderPath + '/'}${file.name}`"
													:label="file.name">
													<div class="dF aC">
														<span class="mr-2">
															<img v-if="file.fileType === 'image'"
																style="width:30px; height:30px; object-fit:cover;"
																:src="`${file.url}`" />
															<a-icon v-else
																:type="fileTypeIconObj[file.fileType] || 'file'"
																style="font-size: 30px;" />
														</span>
														<div class="f1">
															<div><strong>{{ file.name }}</strong></div>
															<div>
																Folder: {{ file.folderPath === '/' ? 'Main' :
																	file.folderPath }}
															</div>
														</div>
													</div>
												</a-select-option>
											</template>

											<a-input>
												<a-icon slot="suffix" type="search" />
											</a-input>
										</a-auto-complete>

										<!-- Dash Divider -->
										<div style="height: 40px;" class="mr-3 ml-3 dash-divider" />
										<!-- Dash Divider -->

										<a-button style="padding: 0 5px; width: 120px;" size="large" type="primary"
											:icon="windowWidth > 1024 ? 'upload' : ''"
											@click="$store.commit('uploadDialog', folders)">
											Upload </a-button>
									</div>
								</a-col>
							</a-row>

							<!-- Media Files -->
							<a-row :gutter="16">
								<a-col :span="windowWidth >= 1205 ? 18 : 24">
									<vue-custom-scrollbar class="imageScroll" style="height:400px; overflow-y:scroll"
										:suppresScrollX="true">
										<a-row :gutter="16" type="flex" v-if="currentFilesFiltered.length">
											<a-col v-for="item in currentFilesFiltered" :key="item.id" class="mb-3">
												<FileHolder :item="item" :checkItems="checkbox"
													@select="selectItem(item)" @onFileSelect="onFileSelect"
													@preview="previewFile(item)" @folderChange="folderChange"
													:fileIcon="fileTypeIconObj[item.fileType] || 'file'" />
												<a-tooltip overlayClassName="change-tooltip-color" placement="right">
													<template slot="title">
														{{ item.name }}
													</template>
													<div :title="item.name"
														style="margin-top:-37px; position: relative; z-index: 100; user-select: none;">
														<h6
															style="overflow: hidden; text-overflow: ellipsis; white-space: nowrap; width: 130px;">
															{{ item.name }}
														</h6>
													</div>
												</a-tooltip>
											</a-col>
										</a-row>
										<div v-else class="w-full h-full dF aC jC">
											<div>
												<div class="dF jC">
													<img v-if="filter == 'images'"
														src="https://ss3.nyc3.digitaloceanspaces.com/bh-prod/no-instance/no_image_1a21134b93.png"
														style="object-fit: contain; height: 275px;" />
													<img v-else-if="filter == 'videos'"
														src="https://ss3.nyc3.digitaloceanspaces.com/bh-prod/no-instance/no_video_a6e4ee5574.png"
														style="object-fit: contain; height: 275px;" />
													<img v-else-if="filter == 'pdf'"
														src="https://ss3.nyc3.digitaloceanspaces.com/bh-prod/no-instance/no_doc_a9353688ec.png"
														style="object-fit: contain; height: 275px;" />
													<img v-else-if="filter == 'unsorted'"
														src="https://ss3.nyc3.digitaloceanspaces.com/bh-prod/no-instance/no_other_46637730b8.png"
														style="object-fit: contain; height: 275px;" />
													<img v-else
														src="https://bildhive.nyc3.digitaloceanspaces.com/a8523ae6e2d84e00b044ccef14c8b39a.svg"
														style="object-fit: contain; height: 275px;" />
												</div>
												<p class="mt-3 text-lg text-center">Oops, There are no items found in
													this folder...</p>
											</div>
										</div>
									</vue-custom-scrollbar>
								</a-col>

								<template v-if="windowWidth >= 1205">
									<a-col :span="6" v-if="showFile">
										<div
											style="border:solid 1px; border-radius:4px; border-color:var(--off-white-dark);">
											<div @mouseover="showOptions = true" @mouseleave="showOptions = false"
												class="relative">
												<div v-show="showOptions"
													style="position:absolute; z-index: 1000; width:100%; height:200px;background-color: rgba(64, 69, 76, 0.5); text-align: center;">
													<div style="margin-top: 85px;">
														<a-radio-group :class="showOptions ? 'show-options' : ''">
															<a-radio-button @click="viewFile(selectedFile)"><a-icon
																	type="eye" /></a-radio-button>
															<a-radio-button @click="shareFile(selectedFile)"><a-icon
																	type="link" /></a-radio-button>
														</a-radio-group>
													</div>
												</div>

												<img v-if="selectedFile.fileType === 'image'" :src="selectedFile.url"
													style="width:100%; height:200px; object-fit:contain; cursor: pointer;" />
												<div v-else
													style="display: flex; justify-content: center; align-items: center;">
													<a-icon :type="fileTypeIconObj[selectedFile.fileType] || 'file'"
														style="font-size: 175px;" class="p-3" />
												</div>
											</div>
										</div>
										<h6 class="mt-3">{{ selectedFile.name }}</h6>
										<p>{{ formatFileSize(selectedFile.size) }}</p>
									</a-col>
									<a-col v-else :span="6" style="height: 200px;">
										<div style="border: 1px solid var(--off-white-dark); height: 100%;"> </div>
									</a-col>
								</template>

								<a-col :span="24" style="text-align:end">
									<a-button @click="$store.commit('CLOSE_LIBRARY')" class="mr-3 cancel-button"
										size="large">CANCEL</a-button>
									<a-button @click="submit" :disabled="!showFile" size="large"
										type="primary">SELECT</a-button>
								</a-col>
							</a-row>
						</a-col>
					</a-row>
				</template>

				<template v-else>
					<div class="dF jC fC aC w-full mt-8">
						<img v-if="filter == 'images'"
							src="https://ss3.nyc3.digitaloceanspaces.com/bh-prod/no-instance/no_image_1a21134b93.png"
							style="object-fit: contain; height: 275px;" />
						<img v-else-if="filter == 'videos'"
							src="https://ss3.nyc3.digitaloceanspaces.com/bh-prod/no-instance/no_video_a6e4ee5574.png"
							style="object-fit: contain; height: 275px;" />
						<img v-else-if="filter == 'pdf'"
							src="https://ss3.nyc3.digitaloceanspaces.com/bh-prod/no-instance/no_doc_a9353688ec.png"
							style="object-fit: contain; height: 275px;" />
						<img v-else-if="filter == 'unsorted'"
							src="https://ss3.nyc3.digitaloceanspaces.com/bh-prod/no-instance/no_other_46637730b8.png"
							style="object-fit: contain; height: 275px;" />
						<img v-else
							src="https://bildhive.nyc3.digitaloceanspaces.com/a8523ae6e2d84e00b044ccef14c8b39a.svg"
							style="object-fit: contain; height: 275px;" />
						<p class="mt-3 text-lg">Oops, There are no items found in this folder...</p>
						<a-button class="mt-5" size="large" type="primary" icon="upload"
							@click="$store.commit('uploadDialog', folders)"> Upload </a-button>
					</div>
				</template>
			</div>
		</a-modal>
	</div>
</template>

<script>
const isVideo = (file) => {
	return file.mime?.includes('video')
}
const isImage = (file) => {
	return file.mime?.includes('image')
}
const isAudio = (file) => {
	return file.mime?.includes('audio')
}
const isDocs = (file) => {
	return file.mime?.includes('msword') || file.ext === 'doc' || file.ext === 'docx' || file.ext === 'txt'
}
const isSheet = (file) => {
	return file.mime?.includes('sheet') || file.ext === 'xls' || file.ext === 'xlsx' || file.ext === 'csv'
}
const isPPT = (file) => {
	return file.mime?.includes('powerpoint') || file.ext === 'ppt' || file.ext === 'pptx' || file.ext === 'pps'
}
const isPdf = (file) => {
	return file.ext === '.pdf' || file.mime?.includes('pdf')
}
const isCompressed = (file) => {
	return file.ext === '.zip' || file.ext === '.rar' || file.ext === '.7z'
}
const isOthers = (file) => {
	return !isVideo(file) && !isImage(file) && !isDocs(file)
}
const isExt = (file, ext) => file.ext?.toLowerCase() === ext?.toLowerCase();

import vueCustomScrollbar from 'vue-custom-scrollbar'
import FileUpload from './upload'
import FileHolder from './FileHolder'
import { formatFileSize } from '../../index'

export default {
	components: {
		FileUpload,
		vueCustomScrollbar,
		FileHolder,
	},
	props: {
		multiple: {
			type: Boolean,
			default: false
		},
	},
	data() {
		return {
			fileTypes: '',
			sortBy: 'name-asc',
			submitFile: {},
			submitFiles: [],
			link: false,
			shareLink: '',
			showOptions: false,
			searchResult: [],
			checkbox: [],
			showFile: false,
			selectedFile: {
				url: '',
				size: '',
				name: '',
				mime: '',
				ext: '',
				id: '',
				fileType: ''
			},
			filter: '',
			previewFile: {
				type: '',
				url: '',
				show: false
			},
			currentFilesShowing: [],
			files: [],
			filesLoaded: false,
			currentFolder: '__all__',
			windowWidth: window.innerWidth,
			sortOptionList: [
				{ name: 'Name (A-Z)', value: 'name-asc' },
				{ name: 'Name (Z-A)', value: 'name-desc' },
				{ name: 'Date (Oldest First)', value: 'date-asc' },
				{ name: 'Date (Newest First)', value: 'date-desc' },
			],
			fileTypeIconObj: {
				video: 'video-camera',
				audio: 'audio',
				pdf: 'file-pdf',
				docs: 'file-word',
				sheet: 'file-excel',
				ppt: 'file-ppt',
				compressed: 'file-zip',
				others: 'file',
				folder: 'folder',
			}
		}
	},
	mounted() {
		window.onresize = () => {
			this.windowWidth = window.innerWidth
		}
	},
	watch: {
		currentFolder(val) {
			if (this.mediaSelect.type === 'all') {
				this.filter = 'all'
			}
		},

		showMedia(val) {
			let filter = this.mediaSelect.type
			if (val) {
				this.getUploadedFiles();
			} else {
				this.showFile = false
				this.checkbox = []
				this.submitFiles = []
				this.sortBy = 'name-asc'
			}
			this.filter = filter
		}
	},
	computed: {
		treeData() {
			return this.$store.state.fileStructure;
		},
		folders() {
			return this.filterFolderStructure(this.treeData || [])
		},
		showMedia() {
			return this.mediaSelect.show
		},
		mediaSelect() {
			return this.$store.state.library.mediaSelect
		},
		allowedMimeTypes() {
			return this.mediaSelect?.allowedMimeTypes || []
		},
		instance() {
			return this.$store.state.instance
		},
		allFiles() {
			let files = []
			Object.values(this.files).forEach(array => {
				files = [...files, ...array]
			})
			return files
		},
		searchFiles() {
			if (this.filter === 'all') return this.allFiles
			return this.filterFileList(this.allFiles)
		},
		currentFiles() {
			let files = this.allFiles;
			if (!this.currentFolder) this.currentFolder = '/';
			if (this.currentFolder !== '__all__') {
				files = this.files[this.currentFolder] || [];
			}
			if (!files?.length) {
				return [];
			}

			const sortFunctions = {
				'name-asc': (a, b) => a.name.localeCompare(b.name),
				'name-desc': (a, b) => b.name.localeCompare(a.name),
				'date-asc': (a, b) => new Date(a.createdAt) - new Date(b.createdAt),
				'date-desc': (a, b) => new Date(b.createdAt) - new Date(a.createdAt),
			};

			return files.sort(sortFunctions[this.sortBy]);
		},
		currentFilesFiltered() {
			if (this.filter === 'all') return this.currentFiles
			return this.filterFileList(this.currentFiles)
		},
	},
	methods: {
		formatFileSize,

		filterFileList(files) {
			let f = this.filter;
			if (f[0] === '.') {
				f = this.filter.split(',');
				if (f.length > 1) {
					return files.filter(x => {
						return x.ext && f.includes(x.ext.toLowerCase());
					})
				} else {
					return files.filter(x => {
						return (f[0][0] === '.' && isExt(x, f[0]));
					})
				}
			} else {
				return files.filter(x => {
					return (f === 'images' && isImage(x)) ||
						(f === 'image' && isImage(x)) ||
						(f === 'docs' && isDocs(x)) ||
						(f === 'pdf' && isPdf(x)) ||
						(f === 'videos' && isVideo(x)) ||
						(f === 'video' && isVideo(x)) ||
						(f === 'others' && isOthers(x))
				})
			}
		},

		async getUploadedFiles() {
			this.$nprogress.start();
			try {
				let { data } = await this.$api.get(`/hives/:instance/file-navigation`)
				if (this.allowedMimeTypes.length) {
					data = data.filter(x => this.allowedMimeTypes.includes(x.mime))
				}
				const folderStructure = this.generateFolderStructure(data)
				this.$store.commit('SET_FILE_STRUCTURE', folderStructure)
				const fileStructure = this.generateFolderFiles(data);
				this.FILE_IT(fileStructure);
			} catch (error) {
				console.error("Error while fetching file structure", error);
			} finally {
				this.$nprogress.done();
			}
		},

		generateFolderFiles(data, parentPath = "") {
			let result = parentPath ? {} : { "/": [] };

			data.forEach((d) => {
				if (d.items) {
					const path = parentPath
						? parentPath + "/" + d.name
						: d.name;
					result[path] = d.items.map(f => {
						if (f.folder) {
							return {
								...f,
								folder: f.folder.replace('/', '')
							}
						} else {
							return f;
						}

					});
					const child = this.generateFolderFiles(d.items, path);
					result = { ...result, ...child };
				} else if (!parentPath) {
					result["/"].push(d);
				}
			});

			return result;
		},

		generateFolderStructure(data, parentPath = '') {
			const result = [];

			if (!parentPath) {
				result.push({
					title: "Main",
					value: '/'
				})
			}
			data.forEach(d => {
				if (d.items) {
					const path = parentPath ? parentPath + '/' + d.name : d.name
					const child = this.generateFolderStructure(d.items, path)
					result.push({
						title: d.name,
						value: path,
						children: child
					})
				}
			})

			result.push({
				title: "+ New Folder",
				value: parentPath ? parentPath + '/__newFolder' : '__newFolder'
			})

			return result;
		},

		filterFolderStructure(data = []) {
			const result = []

			data.forEach(d => {
				if (d.title !== '+ New Folder') {
					const child = this.filterFolderStructure(d.children)
					result.push({
						title: d.title,
						key: d.value,
						children: child
					})
				}
			})
			return result;
		},

		onClose() {
			document.querySelectorAll('.highlight-file').forEach(x => x.classList.remove('highlight-file'))
			this.$store.commit('CLOSE_LIBRARY')
			this.filesLoaded = false
			setTimeout(() => {
				this.files = []
			}, 300)

		},
		changeFolder(newFolder) {
			this.currentFolder = newFolder;
			// remove 'ant-tree-treenode-selected' class from all tree nodes
			document.querySelectorAll('.ant-tree-treenode-selected').forEach(x => x.classList.remove('ant-tree-treenode-selected'))
			// remove 'ant-tree-node-selected' class from all tree nodes
			document.querySelectorAll('.ant-tree-node-selected').forEach(x => x.classList.remove('ant-tree-node-selected'))
		},
		async optionClicked(id, folder) {
			this.changeFolder(folder)

			await document.querySelectorAll('.highlight-file').forEach(x => x.classList.remove('highlight-file'))
			const mediaItem = document.querySelector(`[data-file-id="${id}"]`)
			if (mediaItem) {
				mediaItem.scrollIntoView();
				mediaItem.classList.add('highlight-file')
			}
		},
		submit() {
			if (this.multiple) {
				this.mediaSelect.callback(this.submitFiles)
			} else {
				this.mediaSelect.callback(this.submitFile)
			}
			this.$store.commit('CLOSE_LIBRARY')
		},
		changeCurrentFolder(value) {
			if (this.currentFolder !== value) {
				this.currentFolder = value
				this.showFile = false
				this.checkbox = []
				this.submitFiles = []
				this.sortBy = 'name-asc'
			}
		},
		folderChange(value) {
			this.currentFolder = value;
			this.showFile = false
			this.checkbox = []
			this.submitFiles = []
			this.sortBy = 'name-asc'
		},
		copyLink() {
			var copyText = document.getElementById("myInput");

			copyText.select();
			copyText.setSelectionRange(0, copyText.value.length)
			document.execCommand("copy");
			this.$message.success('Link Copied!')
		},
		shareFile(item) {
			this.link = true
			this.shareLink = item.url
		},
		handleSearch(input) {
			if (input && input.length > 2) {
				this.searchResult = Object.values(this.searchFiles).filter(({ name, url }) => {
					name = name.toLowerCase()
					input = input.toLowerCase()
					return url && name.includes(input)
				})
			} else {
				return this.searchResult = []
			}
		},
		onFileSelect(checked, file) {
			if (checked == true) {
				this.showFile = true
				if (this.multiple) {
					this.checkbox.push(file.id)
				} else {
					this.checkbox = [file.id]
				}

				this.selectedFile.url = file.url
				this.selectedFile.name = file.name
				this.selectedFile.size = file.size
				this.selectedFile.mime = file.mime
				this.selectedFile.id = file.id
				this.selectedFile.ext = file.ext
				this.selectedFile.fileType = file.fileType
				if (this.multiple) {
					this.submitFiles.push(file)
				} else {
					this.submitFile = this.selectedFile
				}
			} else {
				if (this.multiple) {
					let findIndex = this.checkbox.findIndex(x => x === file.id)
					this.checkbox.splice(findIndex, 1)
					if (this.checkbox && this.checkbox.length == 0) {
						this.showFile = false
					}
					let findFileIndex = this.submitFiles.findIndex(x => x.id === file.id)
					this.submitFiles.splice(findFileIndex, 1)
				} else {
					this.showFile = false
					this.checkbox = []
					this.submitFile = {}
				}

			}
		},
		selectItem(e) {
			this.mediaSelect.callback(e)
			this.$store.commit('CLOSE_LIBRARY')
		},
		viewFile(file) {
			let type = null
			if (file.mime.includes('image')) {
				type = 'image'
			} else if (file.mime.includes('video')) {
				type = 'video'
			}
			if (type === null) return window.open(file.url);
			this.previewFile = {
				type,
				url: file.url,
				show: true
			}
		},
		FILE_IT(data) {
			if (!data['/']) data['/'] = []
			for (let i in data) {
				data[i].forEach(x => {
					if (typeof x.url == 'string' && !x.url.includes('https://') && !x.url.includes('http://')) {
						x.url = 'https://' + x.url
					}
					x.folderPath = i
					x.fileType = x.items ? 'folder' : isVideo(x) ? 'video' : isImage(x) ? 'image' : isAudio(x) ? 'audio' : isDocs(x) ? 'docs' : isPdf(x) ? 'pdf' : isSheet(x) ? 'sheet' : isPPT(x) ? 'ppt' : isCompressed(x) ? 'compressed' : isOthers(x) ? 'others' : 'unknown'
				})
			}
			this.files = data
			this.currentFolder = this.currentFolder !== '/' ? this.currentFolder : '/'
			setTimeout(() => {
				this.filesLoaded = true
			}, 100)
		},
	},
}
</script>
<style scoped>
.folder-name {
	line-height: 40px;
	max-width: 100%;
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
	cursor: pointer;
}

.folder-name:hover {
	background-color: var(--off-white-dark);
}

.show-options {
	z-index: 100
}
</style>
<style lang="scss" module>
@import "./style.module.scss";
</style>
<style lang="scss">
.removeScroll::-webkit-scrollbar {
	display: none;
}

.removeScroll {
	-ms-overflow-style: none;
	/* IE and Edge */
	scrollbar-width: none;
	/* Firefox */
}

.imageScroll .ps__thumb-x {
	display: none;
}

.menu-left-scroll .ps__rail-y {
	display: none;
}

.menu-left-scroll .ps__rail-x {
	display: none;
}

.imageScroll .ps__rail-y {
	display: none;
}

.imageScroll .ps__rail-x {
	display: none;
}

.hive-cat-grid {
	display: grid;
	grid-template-columns: 1fr 1fr;
	grid-gap: 30px;
}

.app-store-tabs {
	.ant-tabs-bar {
		background: white;
		padding: 10px 100px 20px
	}
}

.file-list-preview {
	.ant-card {
		position: relative;

		&:hover {
			[aria-label="icon: close"] {
				opacity: 1;
				transform: translate3d(-50%, 0, 0);
			}

			.ant-card-body {
				background: var(--primary-vl);
			}
		}
	}

	.ant-card-body {
		padding: 10px 5px 8px;
	}

	.ant-card-meta-title {
		text-align: center;
		font-size: 12px;
	}

	[aria-label="icon: close"] {
		opacity: 0;
		transition: opacity .3s ease-out, transform .3s ease-out;
		;
		position: absolute;
		left: 50%;
		top: 25px;
		transform: translate3d(-50%, -70%, 0);
		background: white;
		padding: 5px;
		border-radius: 50%;
		cursor: pointer;
		box-shadow: 0 2px 5px rgba(50, 50, 50, 0.5);

		&:hover {
			background: var(--primary);
			color: white;
		}
	}
}

.list-item {
	display: inline-block;
	border: 1px solid var(--off-white-dark);
	padding: 10px;
}

.list-enter-active,
.list-leave-active {
	transition: all .5s;
}

.list-enter,
.list-leave-to

/* .list-leave-active below version 2.1.8 */
	{
	opacity: 0;
	transform: scale(0);
}

.files-select-grid {
	display: grid;
	grid-template-columns: repeat(5, minmax(0, 1fr));
	grid-gap: 20px;

	>div {
		grid-column: span 1;
	}

	.itemContent {
		height: 100px;
	}
}

.tree-folder {
	max-width: 224px;
	white-space: nowrap;
	text-overflow: ellipsis;
	overflow: hidden;
}

.change-tooltip-color .ant-tooltip-content .ant-tooltip-inner {
	background: #3F3356;
}
</style>
<style>
.cancel-button.ant-btn {
	border-color: #ECE9F1 !important;
	background-color: #ECE9F1;
	color: #3F3356
}

@-webkit-keyframes blackWhite {
	0% {
		border-color: var(--orange);
	}

	50% {
		border-color: var(--orange);
	}

	51% {
		border-color: white;
	}

	100% {
		border-color: white;
	}
}

.highlight-file {
	height: 150px;
	border-color: white;
	-webkit-animation-name: blackWhite;
	-webkit-animation-duration: 10s;
}
</style>
