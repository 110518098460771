<template>
	<div>
		<a-modal :title="null" centered :zIndex="1002" :confirmLoading="upLoading" v-model="uploadDialog" :width="800"
			:footer="null" @cancel="closeModal">
			<div class="clearfix">
				<div>
					<h4>Upload</h4>
					<a-alert v-if="errorInFileList" class="my-3"
						message="A file(s) already exists with the same name. Reupload with a new name or force upload to overwrite the file."
						type="error" show-icon />
					<a-alert v-if="errorFileList && errorFileList.length" class="my-3"
						message="Error File(s) will not be uploaded on upload action. Please review your file list."
						type="error" show-icon />
					<a-row :gutter="16">
						<a-col :span="12">
							<a-upload-dragger :showUploadList="false" :beforeUpload="beforeUpload" multiple directory>
								<svg xmlns="http://www.w3.org/2000/svg" width="63.43" height="84.573"
									viewBox="0 0 63.43 84.573">
									<path id="Icon_awesome-file-upload" data-name="Icon awesome-file-upload"
										fill="#b0b1b5"
										d="M37,22.465V0H3.964A3.955,3.955,0,0,0,0,3.964V80.609a3.955,3.955,0,0,0,3.964,3.964h55.5a3.955,3.955,0,0,0,3.964-3.964V26.429H40.965A3.976,3.976,0,0,1,37,22.465ZM47.767,58.146H37V71.36A2.642,2.642,0,0,1,34.358,74H29.072a2.642,2.642,0,0,1-2.643-2.643V58.146H15.663A2.643,2.643,0,0,1,13.8,53.626L29.728,37.818a2.816,2.816,0,0,1,3.971,0L49.625,53.626A2.642,2.642,0,0,1,47.767,58.146Zm14.506-40.8L46.1,1.156A3.961,3.961,0,0,0,43.294,0H42.286V21.143H63.43V20.136A3.952,3.952,0,0,0,62.273,17.344Z" />
								</svg>
								<p class="mt-3">Drag and drop individual file(s) or folder</p>
							</a-upload-dragger>

							<div style="text-align:center" class="dF jSB">
								<a-upload :showUploadList="false" :beforeUpload="beforeUpload" multiple>
									<a-button class=" mt-2" size="large" type="primary"> BROWSE FILE(S) </a-button>
									<p v-if="mediaSelect.type === 'images'" class="mt-3">
										Supported Image files: <strong>.JPG, .PNG</strong>
									</p>
								</a-upload>
								<a-upload :showUploadList="false" :beforeUpload="beforeUpload" directory>
									<a-button class=" mt-2" size="large" type="primary"> BROWSE FOLDER</a-button>
								</a-upload>
							</div>
						</a-col>
						<a-col :span="12" class="custom-scrollbar" style="overflow-y:scroll; height:200px">
							<div v-if="fileList && fileList.length">
								<div class="dF" v-for="(file, fileI) in fileList" :key="`filePreview${fileI}`"
									:style="`color: ${file.nameConflict ? 'red' : 'black'}`">
									<p style="width:90%"><strong>{{ file.name }}</strong></p>
									<i style="width:10%; text-alight:end; cursor: pointer;" class="fe fe-trash-2"
										@click="fileList.splice(fileI, 1)" />
								</div>
							</div>
							<div v-else style="text-align:center">
								<p><strong>No selected files</strong></p>
							</div>

							<div v-if="errorFileList && errorFileList.length" class="mt-3">
								<div class="dF" v-for="(file, fileI) in errorFileList" :key="`filePreview${fileI}`"
									style="color: red">
									<p style="width:90%"><strong>{{ file.name }}</strong></p>
									<i style="width:10%; text-alight:end; cursor: pointer;" class="fe fe-trash-2"
										@click="errorFileList.splice(fileI, 1)" />
								</div>
							</div>
						</a-col>
					</a-row>
					<a-row :gutter="16">
						<a-col :span="24" class="mt-5">
							<h6>Choose Folder</h6>
							<a-form layout="vertical">
								<a-form-item>
									<a-tree-select v-model="chosenFolder" show-search style="width: 100%"
										:dropdown-style="{ maxHeight: '400px', overflow: 'auto' }" :tree-data="treeData"
										:replaceFields="{
											children: 'children', title: 'title', key: 'value', value: 'value'
										}" placeholder="Please select">
									</a-tree-select>
								</a-form-item>
								<a-form-item v-if="chosenFolder.includes('__newFolder')"
									:validate-status="newFolderError ? 'error' : ''"
									:help="newFolderError ? 'Should be combination of numbers & alphabets & spaces and underscores' : ''">
									<a-input placeholder="Enter folder name" v-model="newFolder"
										:addon-before="`${chosenFolder.replace('__newFolder', '')}`">
									</a-input>
								</a-form-item>
							</a-form>
						</a-col>
					</a-row>
					<div style="text-align:end">
						<a-button @click="closeModal" size="large" class="cancel-button mr-3">CANCEL</a-button>
						<a-button v-if="errorInFileList" @click="handleUpload(1)" type="primary" size="large"
							:loading="upLoading">FORCE UPLOAD</a-button>
						<a-button v-else @click="handleUpload(0)" type="primary" size="large"
							:loading="upLoading">UPLOAD</a-button>
					</div>
				</div>
			</div>
		</a-modal>
	</div>
</template>

<script>
import { getBase64 } from 'bh-mod'
export default {
	props: {
		selectedFolder: String,
		button: Object,
		f: Object,
		fileTypes: String,
	},
	data() {
		return {
			newFolderError: false,
			upLoading: false,
			fileList: [],
			newFolder: '',
			chosenFolder: '/',
			previewImage: '',
			formData: null,
			previewVisible: false,
			errorInFileList: false,
			errorFileList: []
		}
	},
	watch: {
		newFolder(path) {
			if (!path.match(/^[a-zA-Z0-9 _]*$/)) {
				this.newFolderError = true
			} else this.newFolderError = false
		},
		uploadDialog(val) {
			if (!val) {
				this.upLoading = false
				this.errorInFileList = false
				this.fileList = []
				this.errorFileList = []
				this.newFolder = ''
				this.chosenFolder = '/'
				this.previewImage = ''
				this.formData = null
			} else {
				this.errorFileList = []
				this.chosenFolder = this.currentFolder
				this.errorInFileList = false;
			}
		},
	},
	computed: {
		mediaSelect() {
			return this.$store.state.library.mediaSelect
		},
		treeData() {
			return (this.$store.state.fileStructure && this.$store.state.fileStructure.length && this.$store.state.fileStructure) || [];
		},
		currentFolder() {
			let currentFolder
			if (this.$bhapp === 'filemanager') {
				currentFolder = this.$store.state.currentFolder
			} else {
				if (this.selectedFolder && this.selectedFolder.length) {
					const found = this.treeData.find(f => f.value === this.selectedFolder)
					if (found) {
						currentFolder = this.selectedFolder
					} else {
						currentFolder = '/'
					}
				} else {
					currentFolder = '/'
				}
			}
			return currentFolder
		},
		uploadDialog: {
			get() {
				return this.$store.state.uploadDialog
			},
			set(val) {
				if (!val) {
					this.closeModal()
				}
			}
		}
	},
	methods: {
		closeModal() {
			this.$store.commit('cancelUpload')
		},
		handleUpload(force = 0) {
			if (this.newFolderError) {
				return this.$message.error('Folder name is invalid. Please rename it to valid name.')
			}
			const { fileList } = this;

			if (!fileList || !fileList.length) return this.$message.error('No File(s) found. Please add file(s) first.')
			let folder = this.chosenFolder

			if (folder === '/') folder = '/'
			else if (folder.includes('__newFolder')) {
				folder = this.chosenFolder.replace('__newFolder', this.newFolder)
				if (!folder.trim()) {
					this.newFolderError = true;
					return this.$message.error('No Folder name found. Please add folder name first.')
				}
			}

			const files = {};

			fileList.forEach(obj => {
				let splitPath = obj.file.webkitRelativePath.split('/')
				if (splitPath && splitPath.length > 2) {
					let finalFolder = folder + "/" + splitPath.slice(1, -1).join('/')
					if (!files[finalFolder]) {
						files[finalFolder] = []
					}
					files[finalFolder].push(obj)
				} else {
					if (!files[folder]) {
						files[folder] = []
					}
					files[folder].push(obj)
				}
			})


			this.upLoading = true;
			let count = 0;
			let totalFiles = files && Object.keys(files).length || 0

			for (const folder in files) {
				let fileList = files[folder] || [];
				const formData = new FormData();
				count++;

				fileList.forEach(obj => {
					formData.append('files', obj.file, obj.file.name);
				});

				formData.append('force', force);
				formData.append('path', folder);
				formData.append('instance', this.$store.state.instance.id);

				this.$api.post('/upload', formData, { headers: { 'Content-Type': 'multipart/form-data' } }).then(({ data }) => {
					data.forEach(x => {
						if (x.folder == '') x.folder = '/'
					})
					if (this.$bhapp === 'filemanager') {
						this.$store.commit('UPLOADED_FILES', data)
						this.$store.dispatch('GET_FILE_STRUCTURE');
					} else {
						this.$emit('newFiles', data)
					}
					if (count === totalFiles) {
						this.closeModal()
						this.upLoading = false;
					}
				}).catch((err) => {
					if (err && err.response && err.response.status === 400 && err.response.data && err.response.data.files && err.response.data.files.length) {
						this.errorInFileList = true;
						const errorFiles = err.response.data.files.map(f => f.name) || [];
						this.fileList = this.fileList.map(f => {
							if (errorFiles.includes(f.name)) {
								return {
									...f,
									nameConflict: true
								}
							}
							return f;
						})
						this.$forceUpdate();
					} else {
						this.$message.error('Error uploading. Please review your files and try again.')
					}
				}).finally(() => {
					if (count === totalFiles) {
						this.upLoading = false;
					}
				})
			}
		},

		beforeUpload(file) {
			const isImage = file.type.includes('image')
			const isVideo = file.type.includes('video')
			if (file.type == '' && !file.name.includes('.')) {
				this.$message.error('A folder cannot be dragged and dropped in. Only individual files can be added.')
				return false;
			}
			if (file.name.includes('.') && !isImage && !isVideo && file.type == '') {
				this.errorFileList.push({ name: file.name })
				this.$message.error(`File type is not supported for file "${file.name}"`)
				return false;
			}
			const isLt2M = (file, max = 2) => file.size / 1024 / 1024 < max;

			if (!isLt2M(file, 15) && isImage) {
				this.errorFileList.push({ name: file.name })
				this.$message.error('Image must be smaller than 15MB!');
				return false;
			} else if (isVideo && !isLt2M(file, 250)) {
				this.errorFileList.push({ name: file.name })
				this.$message.error('Videos must be smaller than 250MB!');
				return false;
			} else if (!isImage && !isVideo && !isLt2M(file, 25)) {
				this.errorFileList.push({ name: file.name })
				this.$message.error('Documents/Misc Files must be smaller than 25MB!');
				return false;
			}

			if (file.webkitRelativePath.includes('/')) {
				const folder = file.webkitRelativePath.split('/')[0];
				const foundFolder = this.treeData.find(f => f.value === folder)
				if (foundFolder) {
					this.chosenFolder = folder
				} else {
					this.chosenFolder = this.chosenFolder === '/' || this.chosenFolder === '__newFolder' ? '__newFolder' : this.chosenFolder.includes('/__newFolder') ? this.chosenFolder : this.chosenFolder + '/__newFolder'
					this.newFolder = folder;
				}
			}

			getBase64(file, imageUrl => {
				this.fileList = [...this.fileList, { file, url: imageUrl, name: file.name }];
			});

			return false;
		},
	}
}
</script>

<style lang="scss">
.hide-scrollbar::-webkit-scrollbar {
	display: none;
}

.custom-scrollbar::-webkit-scrollbar {
	width: 5px;
	height: 5px;
}

.custom-scrollbar::-webkit-scrollbar-track {
	background: #F2F2F2;
}

.custom-scrollbar::-webkit-scrollbar-thumb {
	background: var(--grey-purple);
	;
	border-radius: 10px;
}

.custom-scrollbar::-webkit-scrollbar-thumb:hover {
	background: var(--orange);
}
</style>
<style>
.cancel-button.ant-btn {
	border-color: #ECE9F1 !important;
	background-color: #ECE9F1;
	color: #3F3356
}
</style>
