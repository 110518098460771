<template>
	<a-dropdown :trigger="['click']" placement="bottomLeft" @visibleChange="getNotifications">
		<div class="cursor-pointer">
			<a-badge :count="unreadCount">
				<a-avatar shape="circle" size="large" class="notification-bell" :class="unreadCount ? 'animate' : ''">
					<i class="fe fe-bell text-md"></i>
				</a-avatar>
			</a-badge>
		</div>
		<div slot="overlay">
			<div class="hide-scrollbar" style="max-height: 50vh; overflow-y: scroll">
				<a-card type="inner" style="width: 600px; padding: 0" class="notification-overlay">
					<div slot="title" class="dF jSB aC">
						<a-button @click="viewAll" type="primary" ghost>VIEW ALL</a-button>
						<h5>Notifications</h5>
						<a-button @click="deleteAll" type="danger" ghost><a-icon type="delete" />DELETE ALL</a-button>
					</div>
					<a-list itemLayout="horizontal" :dataSource="notifications" v-if="notifications.length">
						<a-list-item slot="renderItem" slot-scope="notification"
							:class="notification.viewed ? 'actioned' : 'fresh'">
							<a-list-item-meta>
								<a-badge slot="avatar" :dot="!notification.viewed">
									<a-icon type="info-circle" class="text-md text-purple" />
								</a-badge>

								<div slot="title" class="mb-0 w-full dF aC" style="gap: 20px">
									<a-row type="flex" justify="end" class="w-full">
										<a-col :span="24" :md="14">
											<div class="invitation-link cursor-pointer"
												@click="performAction(notification)">
												{{ notification.message }}
											</div>
											<small style="color: var(--med-gray)">{{
												formatDate(notification.createdAt)
											}}</small>
										</a-col>
										<a-col :span="24" :md="10" class="dF aC jE cursor-pointer" style="gap: 20px">
											<a-button v-if="notification.action"
												:type="notification.actionType || 'primary'"
												@click="performAction(notification, notification.action)" ghost>
												{{ notification.action }}
											</a-button>
											<a-button v-if="notification.action2"
												:type="notification.action2Type || 'primary'"
												@click="performAction(notification, notification.action2)" ghost>
												{{ notification.action2 }}
											</a-button>
											<a-button v-if="notification.data && notification.data.eventGroup"
												:type="'primary'"
												@click="toEventGroup(notification, notification.data.eventGroup)" ghost>
												View Event
											</a-button>
											<a-icon @click="remove(notification)" type="delete" class="color-inherit"
												style="color: var(--danger); font-size: large; " />
										</a-col>
									</a-row>
								</div>
							</a-list-item-meta>
						</a-list-item>
					</a-list>
					<div class="dF fC jC aC py-3" v-else-if="processing">
						<a-icon type="loading" style="font-size: 50px; color: #ddd" />
					</div>
					<div class="dF fC jC aC py-3" v-else-if="requested && notifications.length === 0">
						<a-icon type="smile" slot="icon" style="font-size: 50px; color: #ddd" />
						<div class="pt-2">No Notifications</div>
					</div>
				</a-card>
			</div>
		</div>
	</a-dropdown>
</template>

<script>
import { formatDate, checkStale, setCookie, getCookie } from "bh-mod";
import { getRoute } from 'bh-mod/components/layout/TopBar/Actions/notificationHelper'
import { mapActions } from "vuex";

export default {
	data() {
		return {
			timer: null,
			count: 1,
			notifications: [],
			unreadCount: 0,
			requested: false,
			processing: false,
			confirmLoading: false,
			invitation: {
				id: '',
				show: false,
				instance: {},
				role: {},
				email: '',
				declineToken: '',
				notification: {}
			},
			invitations: [],
		};
	},

	computed: {
		unread() {
			return this.notifications.filter((x) => !x.viewed);
		},

		currentURL() {
			return window.location.href.substring(0, window.location.href.indexOf(this.tld) + 3);
		},

		adminApp() {
			return window.location.href.includes('https://admin.bildhive')
		},

		invitationList() {
			return this.$store.state.invitations || [];
		},

		user() {
			return this.$store.state.user && this.$store.state.user.user || null;
		},
	},

	watch: {
		invitationList() {
			this.requested = false;
			this.getNotifications();
		}
	},

	async created() {
		if (this.user?.id) {
			this.getCount();
			this.getInvitations();
		}
	},

	methods: {
		...mapActions(["getInstances"]),

		viewAll() {
			this.$router.push("/notifications");
		},
		deleteAll() {
			let self = this;
			this.$confirm({
				title: "Delete All Notifications",
				content: (h) => (
					<div>Do you want to delete all notifications?</div>
				),
				okText: "Delete",
				okType: "danger",
				cancelText: "Cancel",
				centered: true,
				onOk() {
					self.$api
						.get(`/notifications/clearall`)
						.then(({ data }) => {
							if (data.success) {
								self.notifications = [];
								self.unreadCount = 0;
							}
						}).catch(err => {
							self.$message.error(self.$err(err))
						});
				},
				onCancel() {
					console.log("Cancel");
				},
			});
		},
		async getCount() {
			try {
				let { data } = await this.$api.get("/notifications/count");
				if (data > 0) this.requested = false;
				this.unreadCount = data;
			} catch (err) {
				if (!err || !err.response || !err.response.status || err.response.status !== 400) {
					this.$message.error(this.$err(err, 'Error while fetching notification count. Please try again.'))
				}
			}
		},
		async getNotifications() {
			if (this.requested) return null;

			try {
				this.processing = true;
				let { data } = await this.$api.get("/notifications");

				this.processing = false;
				this.requested = true;

				this.notifications = data.filter((notification) => {
					return !notification.deleted;
				}).map((notification) => {
					return getRoute(notification, this.$tld);
				});
			} catch (err) {
				this.requested = true;
				if (!err || !err.response || !err.response.status || err.response.status !== 400) {
					this.$message.error(this.$err(err, 'Error while fetching notifications. Please try again.'))
				}
			}
		},
		async remove(notification) {
			try {
				this.$api.delete("/notifications/" + notification.id);
				this.notifications = this.notifications.filter((x) => x.id != notification.id);
				if (notification.viewed == false) {
					this.unreadCount = Math.max(0, this.unreadCount - 1);
				}
			} catch (err) {
				if (!err || !err.response || !err.response.status || err.response.status !== 400) {
					this.$message.error(this.$err(err, 'Error while removing notification. Please try again.'))
				}
			}
		},
		formatDate,
		toEventGroup(notification, id) {
			let url = `https://contacts.bildhive.${this.$tld}/events/${id}`;

			if (notification.instance && notification.instance.id) {
				if (getCookie("instance") == notification.instance.id && window.location.href.includes('contacts') && !window.location.href.includes('localhost')) {
					this.$router.push(`/events/${id}`);
				} else {
					setCookie("instance", notification.instance.id);
					window.open(url, '_blank')
				}
			}
		},
		async performAction(notification, action = null) {
			if (notification.route) {
				if (notification.setInstance && notification.instance && notification.instance.id) {
					setCookie("instance", notification.instance.id);
				}
				if (notification.route.startsWith(this.currentURL) && notification.redirect && !window.location.href.includes('localhost')) {
					this.$router.push(notification.redirect);
				} else {
					// window.location.href = notification.route;
					window.open(notification.route, '_blank')
				}
			} else if (notification.group === 'instance:invite') {
				let invitation = this.invitations.find(i => i.instance.id === notification.data.instance)
				if (!invitation) {
					await this.getInvitations();
					invitation = this.invitations.find(i => i.instance.id === notification.data.instance);

					if (!invitation) {
						this.$message.error('No Invitation found for the instance. Please refresh the page and try again!')
						return this.getNotifications()
					}
				}
				if (action === 'Accept') {
					this.accept(invitation, notification)
				} else if (action === 'Decline') {
					this.decline(invitation, notification);
				}
			}
			this.$api
				.put("/notifications/" + notification.id)
				.then(({ data }) => {
					if (data.success) {
						let index = this.notifications.findIndex(
							(x) => x.id == notification.id
						);
						if (index != -1) {
							this.notifications[index].viewed = true;
						}
					}
				}).catch(err => {
					this.$message.error(this.$err(err))
				});
		},

		async accept({ instance, role, id, email }, notification) {
			this.invitation = { instance, role, id, email }
			this.invitation.accept = true
			this.invitation.notification = notification
			let self = this
			this.$confirm({
				title: 'Accepting Invitation',
				content: h => <div class='mt-4'>This will accept the invitation to <strong>{instance.name}</strong> with the role of {role.name == 'bh_admin' ? 'Admin' : role.name}. Are you sure?</div>,
				okText: 'OK',
				okType: 'danger',
				cancelText: 'Cancel',
				centered: true,
				confirmLoading: self.confirmLoading,
				onOk() {
					self.confirmedAccept()
				}
			});
		},

		confirmedAccept() {
			this.confirmLoading = true;

			this.$api.put('/user-invites/accept/' + this.invitation.id).then(() => {
				this.$message.success('Congrats! You now have access to ' + this.invitation.instance.name, 3);
				this.markActionPerformed(this.invitation.notification);
			}).catch(() => this.$message.error('Error occurred while accepting invitation. Please try again!'))
		},

		async decline({ instance, role, id, email, declineToken }, notification) {
			this.invitation = { instance, role, id, email, declineToken }
			this.invitation.accept = true
			this.invitation.notification = notification

			let self = this
			this.$confirm({
				title: 'Declining Invitation',
				content: h => <div class='mt-4'>This will decline the invitation to <strong>{instance.name}</strong> with the role of {role.name == 'bh_admin' ? 'Admin' : role.name}. Are you sure?</div>,
				okText: 'OK',
				okType: 'danger',
				cancelText: 'Cancel',
				centered: true,
				confirmLoading: self.confirmLoading,
				onOk() {
					self.confirmedDecline()
				}
			});
		},

		confirmedDecline() {
			this.confirmLoading = true;

			this.$api.get('/user-invites/decline/' + this.invitation.declineToken).then(() => {
				this.$message.warn(`You have declined the invitation for ${this.invitation.instance.name}.`);
				this.markActionPerformed(this.invitation.notification);
			}).catch((error) => {
				console.error('Error while declining invitation', error)
				this.$message.error('Error occurred while declining invitation. Please try again!')
			})
		},

		markActionPerformed(notification) {
			if (this.invitation.id && this.adminApp) {
				this.updateInvitations(this.invitation.id);
			}

			this.$api
				.post(`notifications/${notification.id}/mark-action-performed`)
				.then(({ data }) => {
					this.requested = false;
					this.getNotifications()
					if (data.success) {
						let index = this.notifications.findIndex(
							(x) => x.id == notification.id
						);
						if (index != -1) {
							this.notification[index].viewed = true;
							this.notification[index].isActionPerformed = true;
						}
					}
					this.invitation = {
						id: '',
						show: false,
						instance: {},
						role: {},
						email: '',
						declineToken: '',
						notification: {}
					}
				}).catch((error) => {
					console.log('Error in notification action update', error)
				});
		},

		async getInvitations() {
			try {
				let { data } = await this.$api.get("/user-invites/me");
				this.invitations = data;
				if (this.adminApp) {
					this.$store.commit('SET_INVITATIONS', data)
				}
			} catch (err) {
				if (!err || !err.response || !err.response.status || err.response.status !== 400) {
					this.$message.error(this.$err(err, 'Error while fetching invitations. Please try again.'))
				}
			}
		},

		updateInvitations(id) {
			let index = this.invitations.findIndex((x) => x.id == id);
			if (index != -1) {
				this.invitations.splice(index, 1);
				this.$store.commit('SET_INVITATIONS', this.invitations)
			}
			this.getInstances();
		},
	},

	mounted() {
		let self = this;
		document.addEventListener("visibilitychange", (e) => {
			if (document.visibilityState === "visible") {
				self.timer = setTimeout(() => {
					self.timer = null;
					let { isStale, onContinue } = checkStale();
					if (isStale && !self.isStaleAlready) {
						self.isStaleAlready = true;
						this.$confirm({
							title: "You are editing Multiple Projects at the same time",
							content: (h) => (
								<div>
									By Refreshing you will lose all unsaved
									data.
									<br /> By choosing to Continue, you will get
									to keep this project active.
								</div>
							),
							okText: "CONTINUE",
							cancelText: "REFRESH",
							onOk: () => {
								onContinue();
								self.isStaleAlready = false;
							},
							onCancel: () => {
								self.isStaleAlready = false;
								window.location.href = window.location.href;
							},
						});
					}
				}, 3000);
			} else {
				if (self.timer) {
					clearTimeout(self.timer);
				}
			}
		});
	},
};
</script>

<style lang="scss" module>
@import "./style.module.scss";
</style>

<style lang="scss" scoped>
.invitation-link {
	cursor: pointer;
}

.invitation-link:hover {
	color: var(--orange);
}

@keyframes abanico {
	0% {
		transform: rotate(0deg);
	}

	7.5% {
		transform: rotate(25deg);
	}

	30% {
		transform: rotate(-25deg);
	}

	45% {
		transform: rotate(15deg);
	}

	58% {
		transform: rotate(-10deg);
	}

	70% {
		transform: rotate(5deg);
	}

	87.5% {
		transform: rotate(-2deg);
	}

	100% {
		transform: rotate(0deg);
	}
}

.notification-bell {
	color: var(--dark-gray);
	position: relative;
	background: none;
	transition: background-color 0.3s ease-out;
	border: 1px solid transparent;

	.ant-avatar-string {
		display: block;
	}

	.ant-avatar-string i {
		color: inherit;
	}

	&.animate {
		animation: abanico 1000ms ease-in-out;
	}

	&:hover {
		color: var(--dark-purple);
		// background:var(--off-white-dark);
		border-color: var(--light-purple);
	}
}

.notification-overlay {
	h4 {
		line-height: 1.2em;
		margin-bottom: 0;
	}

	div.ant-card-body {
		padding: 0;
	}

	>.ant-card-type-inner .ant-card-body {
		padding: 10px 15px;
	}

	.ant-list-item {
		padding: 12px 15px;
	}
}
</style>
