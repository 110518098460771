<template>
	<div class="loading-overlay-drawer center-item" :class="{ show: show, 'loading-text': loadingText }">
		<a-icon type="loading" style="font-size: 50px" />
	</div>
</template>

<script>
export default {
	props: ["show", "loadingText"],
};
</script>

<style lang="scss">
.center-item {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 100000;
	display: flex;
	place-items: center;
	place-content: center;
	background: rgba(255, 255, 255, 0.8);
}

.loading-text {
	&:after {
		content: "Please be patient loading takes time...";
		display: inline-block;
		position: absolute;
		margin-top: 8em !important;
	}
}
</style>
