<template>
    <a-modal
        :visible="noteModal.type === 'Note' && noteModal.visible"
        @cancel="onClose"
        @ok="onClose"
        :footer="null"
        :bodyStyle="{padding: '30px'}"
        width="800px"
        :zIndex="1001"
        centered
        >
        <BHLoading :show="loading" />
        <h5 class="mb-5">Add New Note</h5>

        <a-form-model ref="ruleForm" :model="note" :rules="rules">
            <!-- <a-row>
                <a-col :span="24">
                    <a-form-model-item label="Type" prop="Type">
                        <a-select size="large" v-model="type">
                            <a-select-option value="contact">
                                Contact
                            </a-select-option>
                            <a-select-option value="opportunity">
                                Opportunity
                            </a-select-option>
                        </a-select>
                    </a-form-model-item>
                </a-col>
            </a-row> -->

            <a-row>
                <a-col :span="24">
                    <a-form-model-item label="Subject" prop="subject">
                        <a-input v-model="note.subject" size="large" placeholder="Subject" />
                    </a-form-model-item>
                </a-col>
            </a-row>

            <a-row>
                <a-col :span="24">
                    <a-form-model-item label="Note" prop="content">
                        <a-textarea
                            v-model="note.content"
                            :auto-size="{ minRows: 6, maxRows: 6 }"
                            placeholder="Add note"
                            />
                    </a-form-model-item>
                </a-col>
            </a-row>

            <a-row>
                <template v-if="type == 'contact'">
                <a-col v-if="leadNote == ''" :span="24" v-show="type === 'contact'">
                    <a-form-model-item label="Primary Contact" prop="person" required :rules="type == 'contact' && leadNote == '' ? req('Please select one'):{required:false,message:''}">
                        <searchContact v-if="noteModal.type == 'Note' && noteModal.visible" @updatecontact="updatePerson" />
                    </a-form-model-item>
                </a-col>
                <a-col v-else :span="24">
                    <div class="dF aC" style="line-height: 26.5px; padding-top: 5px; font-size: 14px; color: #40454c;">
                        <div class="dF mr-3"><div>Primary Contact</div>&nbsp;<div style="color:var(--danger)">*</div></div>
                        <i class="fe fe-lock" />
                    </div>
                    <a-form-model-item>
                        <a-input :default-value="defaultName" size="large" disabled />
                    </a-form-model-item>
                </a-col>
                </template>
                <a-col v-if="opportunityNote != ''" :span="24" v-show="type == 'opportunity'">
                    <div class="dF aC" style="line-height: 26.5px; padding-top: 5px; font-size: 14px; color: #40454c;">
                        <div class="dF mr-3"><div>Related To</div>&nbsp;<div style="color:var(--danger)">*</div></div>
                        <i class="fe fe-lock" />
                    </div>
                    <a-form-model-item>
                        <a-input :default-value="defaultName" size="large" disabled />
                    </a-form-model-item>
                </a-col>
                <!-- <a-col :span="24" v-show="type === 'opportunity'">
                    <a-form-model-item label="Related To" prop="person">
                        <a-select
                            v-model="opportunity"
                            mode="single"
                            show-search
                            :show-arrow="false"
                            placeholder="Type to search..."
                            size="large"
                            :not-found-content="null"
                            :filter-option="filterOption"
                            @search="searchAssign">
                            <a-select-option v-for="opp in opportunitySource" :key="opp.id" :value="opp.id">
                                {{ opp.name }}
                            </a-select-option>
                        </a-select>
                    </a-form-model-item>
                </a-col> -->
            </a-row>
        </a-form-model>

        <div style="text-align: right;">
            <a-button @click="onClose" class="mt-3 mr-3 cancel-button" style="width: 125px;" size="large">CANCEL</a-button>
            <a-button @click="onSubmit" style="width: 125px;" type="primary" size="large">CREATE</a-button>
        </div>
    </a-modal>
</template>

<script>
import BHLoading from 'bh-mod/components/common/Loading'
import searchContact from '@/components/common/searchContact'

export default {
    components:{
        BHLoading,searchContact
    },
    data() {
        return {
            timeout:null,
            queriedContacts:[],
            note:{
                subject:'',
                content:'',
            },
            opportunitySource:[],
            type:'contact',
            person:'',
            opportunity:'',
            loading:false,
            rules: {
                subject: [
                    { required: true, message: 'Please input a subject', trigger: 'blur' },
                ],
            },
            defaultName:''
        }
    },
    watch:{
        changeTypeTime(){
            console.log('CHANGE TPYE TIME WATCH RAN')
            this.type = 'opportunity'
            this.opportunity = this.changeType.opportunity.id
            this.opportunitySource = this.opportunities.filter(({id}) => {
                return id == this.opportunity
            })
        },
        visible:{
            handler(val) {
                if (!val) {
                    this.$store.commit('CLEAR_LEAD_ACTION')
                    this.$store.commit('CLEAR_OPPORTUNITY_ACTION')
                }
            }
        },
        leadNote:{
            handler(val) {
                if (val != '') {
                    this.person = val
                    if (this.$store.state.contacts.allContacts[val] && this.$store.state.contacts.allContacts) {
                        let find = this.$store.state.contacts.allContacts[val]
                        if (find && find.firstName && find.lastName && find.firstName.trim() && find.lastName.trim()) this.defaultName = `${find.firstName} ${find.lastName}`
                        else if (find) this.defaultName = `${find.email}`
                    } else if(this.$store.state.contacts.externalContacts && this.$store.state.contacts.externalContacts[val]) {
                        let find = this.$store.state.contacts.externalContacts[val]
                        if (find && find.firstName && find.lastName) this.defaultName = `${find.firstName} ${find.lastName}`
                        else if (find) this.defaultName = `${find.email}`
                    }
                }
            }
        },
        opportunityNote:{
            handler(val) {
                if (val != '') {
                    this.type = 'opportunity'
                    this.person = val
                    this.opportunity = val
                    if (this.$store.state.contacts.opportunities && this.$store.state.contacts.opportunities.find(x => x.id == val)) {
                        let find = this.$store.state.contacts.opportunities.find(x => x.id == val)
                        this.defaultName = `${find.name}`
                    }
                }
            }
        }
    },
    computed:{
        opportunityNote() {
            return this.$store.state.contacts.opportunityAction
        },
        leadNote() {
            return this.$store.state.contacts.leadAction
        },
        visible() {
            return this.$store.state.addNew.visible
        },
        changeTypeTime(){
            return this.$store.state.contacts.changeType.time
        },
        changeType(){
            return this.$store.state.contacts.changeType
        },
        noteModal(){
            return this.$store.state.addNew
        },
        currentPage:{
            get(){
                return this.$store.state.appData.currentPage
            },
            set(val){
                this.$store.commit('SET_CONTACTS_PAGE', val)
            }
        },
        instance(){
            return this.$store.state.instance
        },
        contacts(){
            return Object.values(this.$store.state.contacts.allContacts)
        },
        loadingPage:{
            get(){
                return this.$store.state.appData.loadingPage
            },
            set(val){
                this.$store.commit('LOAD_PAGE',val)
            }
        },
        showOpportunityDetails(){
            return this.$store.state.contacts.opportunityDetails.show
        },
        opportunities(){
            return this.$store.state.contacts.opportunities
        }
    },
    methods:{
        req:msg=>({required:true,message:msg}),
        updatePerson(contact){
			this.$set(this.person, 'contact', contact)
        },
        filterOption(input, option) {
            return (
                option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
            );
        },
        searchAssign(input){
            if(input && input.length>1) {
                this.opportunitySource = this.opportunities.filter(({name}) => {
                    name = name.toLowerCase()
                    input = input.toLowerCase()
                    return name.includes(input)
                })
            } else {
                return this.opportunitySource = []
            }
        },
        onClose(){
            this.note = {
                subject:'',
                content:'',
            }
            this.opportunity = '',
            this.type = 'contact'
            this.opportunitySource = [],
            this.person = ''
			if(this.$refs['ruleForm']){
				this.$refs['ruleForm'].resetFields();
			}
            this.$store.commit('CLOSE_ADD_NEW')
        },
        onSubmit(){
            this.$refs.ruleForm.validate(async valid => {

                if (valid) {
                    this.loading = true

                    let sendObj = JSON.parse(JSON.stringify(this.note))

                    if (this.type == 'contact'){
                        let contactID = this.person
                        this.$api.put(`/contacts/:instance/${contactID}/note`,sendObj).then( ({data}) => {
                            this.$store.commit('UPDATE_CONTACT_NOTES',data)
                            this.loading = false
                            this.onClose()
                        }).catch(err => {
                            this.loading = false
							this.$toastError(err, 'Contact Not Found!')
                        })
                    } else if (this.type == 'opportunity'){
                        let opportunityID = this.opportunity
                        this.$api.put(`/opportunities/:instance/${opportunityID}/note`,sendObj).then( ({data}) => {
                            this.$store.commit('UPDATE_OPPORTUNITY_NOTES', data)
                            this.loading = false
                            this.onClose()
                        }).catch( err => {
                            this.loading = false;
							this.$toastError(err, 'Opportunity Not Found!')
                        })
                    }
                } else {
                    return false;
                }
            });
        },
    },
}
</script>

<style lang="scss">
    .popoverStyle .ant-popover-inner-content {
        padding: 0;
        background-color: white;
        border-radius: 20px;
    }
</style>

<style lang="scss" scoped>
    .popoverContent{
        height: 35px;
        width: 150px;
        line-height: 35px;
        padding-left: 10px;
    }
    .popoverContent:hover{
        background-color: var(--off-white-light);
        cursor: pointer;
    }
</style>
