<template>
	<div class="list-item relative" :data-file-id="item && item.id" style="height: 130px; width: 130px;">
		<a-checkbox :checked="checkItems.includes(item.id)" @change="(e) => onChange(e, item)" class="image-check-box">
			<div v-if="item.url && item.fileType === 'image'" class="image-box"
				:style="{ 'background-image': 'url(' + item.url + ')' }" style="position: relative; top: -23px;" />
			<div v-else-if="item.fileType" class="image-box">
				<a-icon :type="fileIcon" style="color:gray; font-size: 100px;" />
			</div>
		</a-checkbox>
	</div>
</template>

<script>
export default {
	props: ['item', 'checkItems', 'fileIcon'],
	methods: {
		onChange(e, item) {
			if (!item.url) {
				return this.$emit('folderChange', item.folderPath + '/' + item.name)
			}
			this.$emit('onFileSelect', e.target.checked, item)
		}
	},

}
</script>

<style lang="scss" module>
@import "./style.module.scss";
</style>
<style lang="scss">
@import "~bh-mod/styles/filemanager/files.css";

.image-check-box .ant-checkbox {
	float: right;
	right: 3px;
	top: 3px;
	z-index: 100;
}

label.image-check-box.ant-checkbox-wrapper {
	height: 130px;
}
</style>
<style lang="scss" scoped>
.image-box {
	display: table;
	width: 126px;
	height: 130px;
	text-align: center;
	vertical-align: top;
	cursor: pointer;
	background-repeat: no-repeat;
	background-size: contain;
	background-position: 50%;
}

.list-item:hover {
	border: solid 2px;
	border-color: var(--orange);

}

.list-item {
	padding: 0 !important;
}

img {
	object-fit: contain;
	max-width: 100% !important;
	max-height: 100%;
}
</style>
