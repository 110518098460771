<template>
	<a-dropdown :trigger="['click']" placement="bottomLeft">
		<div :class="$style.dropdown" class="user-avatar-image" v-if="user && user.user">
			<a-avatar class="bordered" v-if="user.user.avatar && user.user.avatar !== ''" :src="user.user.avatar"
				shape="circle" size="large" :class="$style.avatar" />
			<a-avatar v-else shape="circle" size="large" class="text-gray-6 bordered" style="background: white"
				:class="$style.avatar">{{
					getInitial(user.user.firstName, user.user.lastName)
				}}</a-avatar>
		</div>
		<a-menu slot="overlay">
			<a-menu-item class="no-hover" v-if="user && user.user">
				<strong>{{ $t("topBar.profileMenu.hello") }},
					{{ user.user.firstName }}</strong>
				<div>
					{{ user.user.email }}
				</div>
			</a-menu-item>

			<a-menu-item v-if="
				($bhapp !== 'admin' || !$route.meta.isinstanceSelect) &&
				$bhapp !== 'superadmin'
			">
				<a :href="`https://admin.bildhive.${tld}/select`" @click.prevent="goTo('/select')">
					<a-icon :class="$style.menuIcon" type="gold" />
					Home
				</a>
			</a-menu-item>
			<a-menu-item>
				<a :href="`https://admin.bildhive.${tld}/settings`" @click.prevent="goTo('/settings')">
					<a-icon :class="$style.menuIcon" type="user" />
					{{ $t("topBar.profileMenu.accountProfile") }}
				</a>
			</a-menu-item>
			<a-menu-item v-if="$bhapp !== 'superadmin' && $userType !== 'agent'">
				<a :href="`https://admin.bildhive.${tld}/settings/projects`"
					@click.prevent="goTo('/settings/projects')">
					<i style="font-size: 14px; margin-right: 0.33rem" class="fe fe-briefcase" />
					Projects
				</a>
			</a-menu-item>
			<a-menu-item v-if="$bhapp !== 'superadmin' && $userType !== 'agent'">
				<a :href="`https://admin.bildhive.${tld}/settings/billing`" @click.prevent="goTo('/settings/billing')">
					<a-icon :class="$style.menuIcon" type="credit-card" />
					Billing
				</a>
			</a-menu-item>

			<a-menu-item>
				<a href="javascript: void(0);" @click.prevent="logout">
					<a-icon :class="$style.menuIcon" type="logout" />
					{{ $t("topBar.profileMenu.logout") }}
				</a>
			</a-menu-item>
		</a-menu>
	</a-dropdown>
</template>

<script>
import { mapState } from "vuex";
import { getInitial, clearAuth } from "bh-mod";

export default {
	computed: {
		...mapState(["user"]),
		tld() {
			return window.location.href.includes("localhost") ||
				window.location.href.includes(".dev")
				? "dev"
				: "com";
		},
	},
	methods: {
		goTo(path) {
			if (this.$bhapp === "admin" || this.$bhapp === "superadmin") {
				if (this.$route.path !== path) {
					this.$router.push(path);
				}
			} else
				window.location.href = `https://admin.bildhive.${this.tld}${path}`;
		},
		getInitial,
		logout() {
			clearAuth(this.$store);

			window.location.href =
				location.href.includes('//localhost:') ? `http://localhost:8080` : this.$bhapp === "superadmin"
					? `https://superadmin.bildhive.${this.tld}/`
					: `https://admin.bildhive.${this.tld}/`;
		},
	},
};
</script>

<style lang="scss" module>
@import "./style.module.scss";
</style>
<style lang="scss">
.user-avatar-image {
	img {
		object-fit: cover;
	}
}

.ant-dropdown-menu-item.no-hover:hover {
	background: transparent;
	cursor: pointer;
}
</style>
