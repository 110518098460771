<template>
	<ul class="relative" style="line-height:50px;">
		<draggable v-if="drag || false" :move="checkMove" :list="items" @end="endMove" :delay-on-touch-only="true"
			:delay="100">
			<template v-for="(item, itemI) in items">

				<li v-if="item.divider" :key="item.value + itemI">
					<hr class="mt-2 mb-2" />
				</li>

				<li v-else :key="item.value + itemI" :class="getClass(item)" class="catTitle appCategory dF"
					style="padding-left: 18px; height: 50px;">

					<div @click="$emit('clicked', item)" class="dF aC" style="flex: 1;">
						<a-icon v-if="item.loading" type="loading" />
						<a-icon v-if="item.ant" :type="item.ant" theme="filled" />
						<i v-else-if="item.fe || item.fa" :type="item.fe ? `fe fe-${item.fe}` : `fa fa-${item.fa}`" />
						<span v-else-if="item.svg" style="width:20px; max-height: 30px;" v-html="item.svg"></span>
						<img v-else-if="item.iconUrl" :src="item.iconUrl" style="width: 20px; max-height: 30px;" />
						<span v-else-if="item.noIcon" style="margin-right: 20px;"></span>

						<template v-if="showMenu">
							<span class="pl-2">{{ item.label }}</span>
						</template>

						<slot v-if="!item.children || !item.children.length && showMenu" name="parent"></slot>
					</div>
					<a-popover placement="right" trigger="click" overlayClassName="remove-padding"
						v-if="item.options && item.options.length">
						<div slot="content">
							<div class="EditMenu pl-3"
								@click="$emit('optionclick', { type: 'parent', itemValue: item.value, optionValue: opt.value })"
								v-for="(opt, optI) in item.options" :key="opt.value + optI">{{ opt.label }}</div>
						</div>
						<div v-if="item.options && item.options.length && showMenu && item.value != '__pinnedMarkers'"
							class="more-option-icon">
							<a-icon type="more" />
						</div>
					</a-popover>
				</li>
			</template>
		</draggable>
		<template v-else v-for="(item, itemI) in items">

			<li v-if="item.divider" :key="item.value + itemI">
				<hr class="mt-2 mb-2" />
			</li>

			<li v-else :key="item.value + itemI" :class="getClass(item)" class="catTitle appCategory dF"
				style="padding-left: 18px; height: 50px;">

				<div @click="$emit('clicked', item)" class="dF aC" style="flex: 1;">
					<a-icon v-if="item.loading" type="loading" />
					<a-icon v-if="item.ant" :type="item.ant" theme="filled" />
					<i v-else-if="item.fe || item.fa" :type="item.fe ? `fe fe-${item.fe}` : `fa fa-${item.fa}`" />
					<span v-else-if="item.svg" style="width:20px; max-height: 30px;" v-html="item.svg"></span>
					<img v-else-if="item.iconUrl" :src="item.iconUrl" style="width: 20px; max-height: 30px;" />
					<span v-else-if="item.noIcon" style="margin-right: 20px;"></span>

					<template v-if="showMenu">
						<span class="pl-2">{{ item.label }}</span>
					</template>

					<slot v-if="!item.children || !item.children.length && showMenu" name="parent"></slot>
				</div>
				<a-popover placement="right" trigger="click" overlayClassName="remove-padding"
					v-if="item.options && item.options.length">
					<div slot="content">
						<div class="EditMenu pl-3"
							@click="$emit('optionclick', { type: 'parent', itemValue: item.value, optionValue: opt.value })"
							v-for="(opt, optI) in item.options" :key="opt.value + optI">{{ opt.label }}</div>
					</div>
					<div v-if="item.options && item.options.length && showMenu && item.value != '__pinnedMarkers'"
						class="more-option-icon">
						<a-icon type="more" />
					</div>
				</a-popover>
			</li>
		</template>

	</ul>
</template>

<script>
import draggable from 'vuedraggable'
export default {
	props: ['showMenu', 'hasPinned', 'selectedValue', 'getClass', 'items', 'drag'],
	components: {
		draggable
	},
	computed: {

	},
	data() {
		return {
			fixed: false
		}
	},
	methods: {
		checkMove(e) {
			this.fixed = this.isDraggable(e.draggedContext)
			return this.isDraggable(e.draggedContext)

		},
		isDraggable(context) {
			const { index, futureIndex } = context
			// let list =
			// this.$emit('reorder', this.items)
			return !(this.items[index].fixed || this.items[futureIndex].fixed);
		},
		endMove(e) {
			if (this.fixed) {
				this.$emit('reorder', e)
			}
		}
	}
}
</script>

<style lang="scss" scoped>
ul {
	list-style: none;
	margin: 0;
	padding: 0;
}

.appCategory:hover {
	cursor: pointer;
	background-color: var(--off-white-light);
}

.appCategory:hover .more-option-icon {
	color: var(--black);
}

.more-option-icon {
	position: absolute;
	display: inline-flex;
	color: white;
	right: 0%;
	width: 22px;
	height: 50px;
	align-items: center;
	justify-content: center;
}

.more-option-icon:hover {
	background: rgba(100, 100, 100, 0.08);
	content: '';
	color: var(--orange) !important;
}

.EditMenu {
	display: flex;
	height: 35px;
	align-items: center;
	width: 180px;
	cursor: pointer;
}

.EditMenu:hover {
	background-color: var(--off-white-light);
}

.catTitle {
	max-width: 224px;
	white-space: nowrap;
	text-overflow: ellipsis;
	overflow: hidden;
	padding-right: 21px;
}

.no-options {
	max-width: 230px !important;
}

.selected-left-nav {
	background: #F7F5F9;
	color: var(--orange);
}
</style>
