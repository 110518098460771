<template>
	<a-layout-sider :width="'auto'" class="bh-menu-left" v-if="theApp">
		<bar />
		<a href="javascript: void(0);" :class="$style.air__menuLeft__backdrop" @click="toggleMobileMenu" />
	</a-layout-sider>
</template>

<script>
import { mapState } from "vuex";
import bar from "@/components/layout/sidebar.vue";

export default {
	name: "menu-left",
	components: { bar },
	computed: {
		...mapState(["settings"]),
		theApp() {
			return this.$store.state.theApp || {};
		},
		appData() {
			return this.$store.state.appData || {};
		},
	},

	methods: {
		toggleMobileMenu() {
			const setting = "isMobileMenuOpen";
			const value = !this.settings[setting];
			this.$store.commit("CHANGE_SETTING", { setting, value });
		},
	},
};
</script>

<style lang="scss" module>
@import "./style.module.scss";
</style>

<style lang="scss">
.bh-menu-left {
	background-color: var(--white);

	.router-link-exact-active {
		color: var(--primary);
	}

	.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
		background: none;

		&:after {
			display: none;
		}
	}

	.ant-menu.ant-menu-inline.ant-menu-root.ant-menu-light {
		border-right: 0;
	}
}

.menu-left-heading {
	padding: 1.2rem 1.33rem;
	font-size: 18px;
	color: #40454c;
}
</style>
