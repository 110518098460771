<template>
	<div class="LeftNavBar h-full dF aS" :class="{ 'second-nav-open': secondNavOpen, 'LeftNavBarMini': !showMenu }">
		<div :class="showMenu ? 'first-bar-left' : ''"
			:style="[!secondNavOpen ? { 'width': '57px' } : { 'border-right': '1px solid rgba(0, 0, 0, 0.1)' }]">
			<div>
				<div class="leftnav_menu-holder">
					<i class="fa fa-bars" @click="showMenu = !showMenu" />
				</div>
				<!-- <a-icon type="menu" class="menu-button mt-5 ml-3" @click="showMenu = !showMenu"/>  -->
				<div class="instance dF" style="padding-left: 12px;" v-if="theApp && theApp.media">
					<img :src="imgSrc || theApp.media.icons.svg" style="width: 30px; max-height: 40px;" />
					<span v-if="showMenu" class="ml-1"> {{ title || theApp.media && theApp.media.initial ||
						theApp.name }}</span>
				</div>
			</div>

			<div style="height: calc(100vh - 200px); width: 100%; overflow-y:auto" class="hidenscroll">
				<div v-if="treeData && treeData.length" class="my-2">
					<a-tree :tree-data="treeData" :auto-expand-parent="false" :replaceFields="{
						children: 'children', title: 'title', key: 'key'
					}" :selectedKeys="selectedKeys" blockNode style="font-size: 1rem">
						<template #title="{ key: treeKey, title, expanded, selected }">
							<div class="appCategory dF aC jSB" @click="$emit('accordion', { value: treeKey })">
								<div class="tree-folder">
									<a-icon :type="expanded ? 'folder-open' : 'folder'" theme="filled"
										style="color:#3f3455" />
									<span v-if="showMenu" class="ml-2">{{ title }}</span>
								</div>

								<a-popover v-if="showMenu && treeKey !== '/'" placement="right" trigger="click"
									overlayClassName="remove-padding">
									<div slot="content">
										<div class="EditMenu pl-3" v-for="(opt, optI) in treeDataAction"
											:key="opt.value + optI"
											@click="$emit('optionclick', { type: 'child', itemValue: treeKey, optionValue: opt.value })">
											{{ opt.label }}
										</div>
									</div>
									<div class="tree-more-icon">
										<a-icon type="more" />
									</div>
								</a-popover>
							</div>
						</template>
					</a-tree>
				</div>
				<template v-else-if="accordion">
					<div v-for="(item, itemI) in items" :key="item.label" @click="parentClicked(item)">
						<div v-if="showMenu && !item.divider" class="dF jSB aC pl-3 pr-2 leftNavItemHover"
							:class="getParentItemClass(item)" style="line-height:50px;">
							<span>
								<a-icon v-if="item.icon" :type="item.icon" theme="filled" />
								<a-icon v-if="item.ant" :type="item.ant" theme="filled" />
								<i v-else-if="item.fe || item.fa"
									:type="item.fe ? `fe fe-${item.fe}` : `fa fa-${item.fa}`" />
								<span v-else-if="item.svg" style="width:20px; max-height: 30px;"
									v-html="item.svg"></span>
								<img v-else-if="item.iconUrl" :src="item.iconUrl"
									style="width: 20px; max-height: 30px;" />
								<span v-else-if="item.noIcon" style="margin-right: 20px;"></span>

								<span class="ml-2">{{ item.label }} </span>
							</span>
							<a-icon @click="showAccordion[itemI].showAccordion = !showAccordion[itemI].showAccordion"
								v-if="item.children && item.children.length && showAccordion[itemI].showAccordion"
								type="caret-down" />
							<a-icon @click="showAccordion[itemI].showAccordion = !showAccordion[itemI].showAccordion"
								v-if="item.children && item.children.length && !showAccordion[itemI].showAccordion"
								type="caret-up" />
						</div>
						<div v-if="showMenu && item.divider">
							<hr class="mt-2 mb-2" />
						</div>
						<template v-if="item.children?.length && showAccordion[itemI].showAccordion">
							<List :drag="drag || false" @reorder="reorderItems" :items="item.children"
								:showMenu="showMenu" :getClass="getItemClass" @clicked="(e) => childClicked(e)"
								@optionclick="(e) => $emit('optionclick', e)" />
						</template>
					</div>
				</template>
				<List v-else :drag="drag || false" @reorder="reorderItems" :items="items" :showMenu="showMenu"
					:hasPinned="hasPinned" :getClass="getItemClass" @clicked="(item) => parentClicked(item)"
					@optionclick="(e) => $emit('optionclick', e)" />
			</div>
		</div>
		<div class="second-bar-left" v-if="secondNavOpen && !splitSecondNav">
			<div class="dF aC">
				<div class="dF aC px-3 py-4" style="width:195px"><a-checkbox
						v-if="bulkSelect && items[secondNav] && items[secondNav].children && items[secondNav].children.length"
						:checked="allCheck || selectedArray.length == items[secondNav].children.length"
						@change="checkAll" />
					<h5 :title="currentTitle" class="catTitle" style="margin-bottom:0px; margin-left: 1.25rem;">
						{{ currentTitle }}
					</h5>
				</div>
				<a-tooltip slot="suffix" class="py-4" style="height: 72px;">
					<a-icon type="close" style="margin-top: 5.5px;" @click="closeSecondNav" />
				</a-tooltip>
			</div>
			<vue-custom-scrollbar
				style="max-height:calc(100vh - 134px); min-height:calc(100vh - 134px); width: 100%;overflow:hidden; position: relative;"
				class="hideScroll py-3 children-list">
				<div class="dF w-full px-3 aC" style="justify-content:flex-end"
					v-if="selectedArray.length && selectedArray.length > 1">
					<div @click="bulkDelete" style="border:1px solid #ECEBED; border-radius:4px; cursor:pointer"
						class="px-3 py-1"><a-icon style="color:#FD637C" type="delete" />&emsp;Bulk Delete</div>
				</div>
				<ul v-if="secondNav != -1 && clickType == 'parent'">
					<li :data-file-id="item.value" v-for="(item, itemI) in items[secondNav].children"
						:key="item.value + itemI" @click="level2Clicked(item)"
						class="pl-3 catTitle appCategory child-item" style="height: 50px; line-height: 50px;"
						:title="item.label">
						<a-checkbox v-if="bulkSelect" @change="toggleSelect(item)"
							:checked="selectedArray.includes(item.value)" />&emsp;
						{{ item.label }}
						<a-popover placement="right" trigger="click" overlayClassName="remove-padding">
							<div slot="content">
								<div class="EditMenu pl-3"
									@click="$emit('optionclick', { type: 'child', itemValue: item.value, optionValue: opt.value, parent: item.parent })"
									v-for="(opt, optI) in item.options" :key="opt.value + optI">{{ opt.label }}</div>
							</div>
							<div v-if="item.options && item.options.length" class="more-option-icon">
								<a-icon type="more" />
							</div>
						</a-popover>
					</li>
				</ul>
				<ul v-else-if="secondNav != -1 && childParent != -1 && clickType == 'child'">
					<li :data-file-id="item.value"
						v-for="(item, itemI) in items[childParent].children[secondNav].children"
						:key="item.value + itemI" @click="level2Clicked(item)" class="pl-3 catTitle appCategory"
						style="height: 50px; line-height: 50px;" :title="item.label">
						{{ item.label }}
						<a-popover placement="right" trigger="click" overlayClassName="remove-padding">
							<div slot="content">
								<div class="EditMenu pl-3"
									@click="$emit('optionclick', { type: 'child', itemValue: item.value, optionValue: opt.value, parent: item.parent })"
									v-for="(opt, optI) in item.options" :key="opt.value + optI">{{ opt.label }}</div>
							</div>
							<div v-if="item.options && item.options.length" class="more-option-icon">
								<a-icon type="more" />
							</div>
						</a-popover>
					</li>
				</ul>
				<div v-else>
					<div slot="content"></div>
				</div>
			</vue-custom-scrollbar>
		</div>
		<div class="second-bar-left" v-else-if="secondNavOpen && splitSecondNav">

			<vue-custom-scrollbar style="height: calc( 100vh - 71.11px ); width: 100%; position: relative;"
				class="hideScroll py-3">
				<ul
					v-if="secondNav != -1 && childParent != -1 && (clickType == 'parent' || clickType == 'child') && items[childParent].children[secondNav].children.length">
					<!-- <div>{{items[childParent].children[secondNav].split}}</div> -->
					<div v-for="(item, itemI) in items[childParent].children[secondNav].children" :key="item.value">
						<div v-if="item.length">
							<div class="dF aC">
								<div class="dF aC px-3 py-4" style="width:195px"><a-checkbox v-if="bulkSelect"
										class="m-3" :checked="splitSelect[itemI].allSelect"
										@change="(e) => secondNavSelectAll(e, itemI)" />
									<h5 class="catTitle" style="margin-bottom:0px; margin-left: 1.25rem;">
										{{ item[0].type }}
									</h5>
								</div>
								<a-tooltip v-if="itemI == 0" slot="suffix" class="py-4" style="height: 72px;">
									<a-icon type="close" style="margin-top: 5.5px;" @click="closeSecondNav" />
								</a-tooltip>
							</div>

							<div class="dF w-full px-3 aC" style="justify-content:flex-end"
								v-if="checkInclude.includes(itemI)">
								<div @click="bulkDeleteSplit(itemI)"
									style="border:1px solid #ECEBED; border-radius:4px; cursor:pointer"
									class="px-3 py-1">
									<a-icon style="color:#FD637C" type="delete" />&emsp;Bulk Delete
								</div>
							</div>
							<li :data-file-id="child.value" v-for="child in item" :key="child.value"
								class="pl-3 catTitle appCategory" style="height: 50px; line-height: 50px;"
								@click="level2Clicked(child)">
								<a-checkbox v-if="bulkSelect" @change="toggleSelect(child)"
									:checked="selectedArray.includes(child.value)" />&emsp;
								{{ child.label }}
								<a-popover placement="right" trigger="click" overlayClassName="remove-padding">
									<div slot="content">
										<div class="EditMenu pl-3"
											@click="$emit('optionclick', { type: 'child', splitParent: splitSelect[itemI], itemValue: child.value, optionValue: opt.value, parent: child.parent })"
											v-for="(opt, optI) in child.options" :key="opt.value + optI">{{ opt.label }}
										</div>
									</div>
									<div v-if="child.options && child.options.length" class="more-option-icon">
										<a-icon type="more" />
									</div>
								</a-popover>
							</li>
						</div>

						<div v-else-if="!item.length">
							<div class="dF aC">
								<div class="dF aC px-3 py-4" style="width:195px"><a-checkbox v-if="bulkSelect"
										class="m-3" :default-checked="false" disabled />
									<h5 class="catTitle" style="margin-bottom:0px; margin-left: 1.25rem;">
										{{ splitSelect[itemI].name }}
									</h5>
								</div>
								<div v-if="itemI == 0">
									<a-tooltip slot="suffix" class="py-4" style="height: 72px;">
										<a-icon type="close" style="margin-top: 5.5px;" @click="closeSecondNav" />
									</a-tooltip>
								</div>
							</div>
							<div class="pl-3">
								{{ splitSelect[itemI].empty }}
							</div>
						</div>
					</div>
				</ul>
			</vue-custom-scrollbar>
		</div>
	</div>
</template>
<script>
import vueCustomScrollbar from 'vue-custom-scrollbar'
import List from 'bh-mod/components/LeftNav/List'
export default {
	components: { vueCustomScrollbar, List },
	props: {
		bulkSelect: {
			default: false,
			type: Boolean
		},
		drag: {
			default: false,
			type: Boolean
		},
		items: {
			default: () => [],
			type: Array
		},
		imgSrc: {
			default: '',
			type: String
		},
		title: {
			default: 'title',
			type: String
		},
		hasChild: {
			default: false,
			type: Boolean
		},
		hasPinned: {
			default: false,
			type: Boolean
		},
		accordion: {
			type: Boolean,
			default: false
		},
		selectedValue: {
			default: '',
			type: String
		},
		selectedParent: {
			default: '',
			type: String
		},
		selectedSecondNav: {
			default: '',
			type: String
		},
		highlightObj: {
			default: () => { },
		},
		openNav: {
			default: () => { },
		},
		openNavCondogrid: {
			default: () => { },
		},
		selectedItem: {
			default: '',
			type: String
		},
		splitSecondNav: {
			default: false,
			type: Boolean
		},
		treeData: {
			default: () => [],
			type: Array
		},
		treeDataAction: {
			default: () => [],
			type: Array
		},
		selectedKeys: {
			default: () => [],
			type: Array
		}
	},
	watch: {
		bulkDeleteArray: {
			handler(val) {
				if (this.$store.state.bulkDeleteArray && val.length == 0) {
					this.selectedArray = []
					this.allCheck = false
				}
			}
		},
		openNavCondogrid: {
			handler(val) {
				if (this.splitSecondNav && val.id != -1 && Object.keys(val.floor).length != 0 && Object.keys(val.lot).length != 0) {
					this.childParent = 0
					let index = this.items[this.childParent].children.findIndex(x => x.value == val.floor.id)
					if (index != -1) {
						this.showAccordion[0].showAccordion = true
						this.secondNav = index
						this.currentTitle = this.items[this.childParent].children[this.secondNav].label
						this.clickType = 'child'
						this.secondNavOpen = true
						this.highlight(val.lot.id)
					}
				}
			},
			deep: true
		},
		highlightObj: {
			handler(val) {
				this.highlight(val.value)
			}
		},
		sidebarCollapse(val) {
			this.showMenu = !val
		},
		'$route': {
			immediate: true,
			handler(to) {
				if (to.meta.hasOwnProperty('sidebarCollapsed'))
					this.showMenu = !to.meta.sidebarCollapsed

			}
		},
		openNav: {
			handler(val) {
				if (this.theApp.subdomain == 'amenities') {
					if (val.type == 'open') {
						let parent = ''
						this.items.forEach(cat => {
							let found = cat.children.find(x => x.value == val.markerId)
							if (found) {
								parent = cat.value
							}
						})
						if (parent != '' && this.secondNavOpen) {
							if (parent == '__pinnedMarkers2') {
								let index = this.items.findIndex(x => x.value == parent)
								if (this.secondNav != index) { //secondnav open incorrect parent selected
									this.secondNav = index
									this.currentTitle = this.items[this.secondNav].label
									// this.$emit('changeCurrentCat', '__pinnedMarkers')
								}
								this.$emit('scrollItem')
								this.$emit('previousMarker', val.markerId)
								this.highlight(val.markerId)
							} else {
								let index = this.items.findIndex(x => x.value == parent)
								if (this.secondNav != index) { //secondnav open incorrect parent selected
									this.secondNav = index
									this.currentTitle = this.items[this.secondNav].label
									this.$emit('changeCurrentCat', parent)
								}
								this.$emit('scrollItem')
								this.$emit('previousMarker', val.markerId)
								this.highlight(val.markerId)
							}
						}
						else { // secondnav close
							if (parent != '') {
								if (parent == '__pinnedMarkers2') {
									let index = this.items.findIndex(x => x.value == parent)
									if (this.secondNav != index) { // incorrect parent selected
										this.secondNav = index
										// this.$emit('changeCurrentCat', '__pinnedMarkers')
									}
									this.currentTitle = this.items[this.secondNav].label
									this.secondNavOpen = true
									this.$emit('scrollItem')
									this.$emit('previousMarker', val.markerId)
									this.highlight(val.markerId)
								} else {
									let index = this.items.findIndex(x => x.value == parent)
									if (this.secondNav != index) { // incorrect parent selected
										this.secondNav = index
										this.$emit('changeCurrentCat', parent)
									}
									this.currentTitle = this.items[this.secondNav].label
									this.secondNavOpen = true
									this.$emit('scrollItem')
									this.$emit('previousMarker', val.markerId)
									this.highlight(val.markerId)
								}
							}
						}
					} else {
						this.secondNavOpen = false
					}
				}
				else if (this.theApp.subdomain == 'siteplan') {
					if (val.type == 'open') {
						let index = this.items.findIndex(x => x.label == this.openNav.item)
						this.secondNav = index
						this.currentTitle = this.items[index].label
						this.secondNavOpen = true
						if (this.title == 'CSPC') {
							this.$emit('scrollItem')
						}
						this.highlight(val.lot)
					}
					else if (val.type == 'close') {
						this.secondNavOpen = false
					}
				}
				else {
					if (val.type == 'open') {
						let index = this.items.findIndex(x => x.label == this.openNav.item)
						this.secondNav = index
						this.currentTitle = this.items[index].label
						this.secondNavOpen = true
						this.$emit('scrollItem')
						this.highlight(val.lot)
					}
					else if (val.type == 'close') {
						this.secondNavOpen = false
					}
				}
			},
			deep: true
		},
		selectedSecondNav: {
			handler(val) {
				if (val != '') this.highlight(val)
			}
		},
		itemsLength(val) {
			this.secondNavOpen = false
			this.secondNav = -1
		},
		secondNavOpen: {
			handler(val) {
				this.$emit('trackNav', val)
			}
		}
	},
	computed: {
		checkInclude() {
			let array = []
			this.selectedArray.forEach(id => {
				this.idArray.forEach(group => {
					group.forEach(x => {
						if (id == x) {
							let index = this.idArray.findIndex(array => array == group)
							array.push(index)
						}
					})
				})
			})
			return array
		},
		idArray() {
			let ids = []
			this.items[this.childParent].children[this.secondNav].children.forEach(x => {
				let array = []
				x.forEach(y => {
					array.push(y.value)
				})
				ids.push(array)
			})

			return ids
		},
		splitSelect() {
			let refresh = this.refresh
			let split = []
			if (this.childParent != -1 && this.secondNav != -1 && this.splitSecondNav) {
				split = this.items[this.childParent].children[this.secondNav].split
			}
			return split
		},
		theApp() {
			return this.$store.state.theApp
		},
		bulkDeleteArray() {
			if (this.$store.state.bulkDeleteArray) return this.$store.state.bulkDeleteArray
			else return []
		},
		pinnedOBJ() {
			let obj = {}
			for (var i = 0; i < this.items.length; i++) {
				if (this.items[i].value == '__pinnedMarkers') {
					obj = this.items[i]
					return obj
				}
			}
		},
		itemsLength() {
			return this.items.length
		}
	},
	data() {
		return {
			allCheck: false,
			secondNavOpen: false,
			currentTitle: '',
			secondNav: -1,
			showMenu: true,
			showAccordion: [],
			clickType: 'parent',
			childParent: -1,
			selectedArray: [],
			refresh: 0,
		}
	},
	methods: {
		bulkDeleteSplit(index) {
			let array = []
			this.selectedArray.forEach(id => {
				if (this.idArray[index].includes(id)) {
					array.push(id)
				}
			})
			this.$emit('bulkDeleteSplit', array)
		},
		bulkDelete() {
			let selected = JSON.parse(JSON.stringify(this.selectedArray))
			this.$emit('bulkDelete', selected)
			if (this.$store.state.bulkDeleteArray) {
				this.$store.commit('UPDATE_BULKDELETE', selected)
			}
			// setTimeout(() => {
			// 	this.selectedArray = []
			// 	this.allCheck = false
			// }, 100)
		},
		checkAll(e) {
			if (e.target.checked) {
				this.allCheck = true
				this.selectedArray = []
				this.items[this.secondNav].children.forEach(child => {
					if (child.value != '__dev') {
						this.selectedArray.push(child.value)
					} else {
						this.$message.error('You cannot delete or select the Development Location')
					}
				})
			} else {
				this.allCheck = false
				this.selectedArray = []
			}
		},
		secondNavSelectAll(e, index) {
			if (e.target.checked) {
				this.splitSelect[index].allSelect = true
				this.idArray[index].forEach(id => {
					if (!this.selectedArray.includes(id)) {
						this.selectedArray.push(id)
					}
				})

			} else {
				this.splitSelect[index].allSelect = false
				this.idArray[index].forEach(id => {
					if (this.selectedArray.includes(id)) {
						let deleteIndex = this.selectedArray.findIndex(x => x == id)
						this.selectedArray.splice(deleteIndex, 1)
					}
				})
			}
			this.refresh = Date.now()
		},
		toggleSelect(item) {
			if (this.selectedArray.includes(item.value)) {
				let index = this.selectedArray.findIndex(x => x == item.value)
				this.selectedArray.splice(index, 1)
				if (this.selectedArray.length == 0) {
					this.allCheck = false
				}

			} else {
				if (item.value != '__dev') {
					this.selectedArray.push(item.value)
				} else {
					this.$message.error('You cannot delete or select the Development Location')
				}
			}
		},
		reorderItems(e) {
			if (this.theApp.subdomain == 'amenities') this.closeSecondNav()
			this.$emit('order', e)
		},
		async highlight(id) {

			await document.querySelectorAll('.highlight').forEach(x => x.classList.remove('highlight'))
			if (document.querySelector(`[data-file-id="${id}"]`)) {
				document.querySelector(`[data-file-id="${id}"]`).classList.add('highlight')
			}
		},
		getItemClass(item) {
			return {
				'selected-left-nav': this.selectedValue === item.value,
			}
		},
		getParentItemClass(item) {
			return {
				'selected-left-nav': this.selectedParent === item.value,
			}
		},
		closeSecondNav() {
			//closes the second nav
			this.secondNavOpen = false
			this.secondNav = -1
		},
		parentClicked(item) {
			this.clickType = 'parent'
			if (item.clicked) {
				this.$emit('opened', { type: 'parent', value: item.value })
				return item.clicked(item)
			}

			if (!this.accordion) {

				let index = this.items.findIndex(x => x.value == item.value)
				this.secondNav = index

				this.currentTitle = item.label
				if (this.hasChild == true && !item.hasOwnProperty('disabledSecondNav')) {
					this.secondNavOpen = true
				}
				if (item.hasOwnProperty('disabledSecondNav')) {
					this.secondNavOpen = false
				}
			}

			this.$emit('opened', { type: 'parent', value: item.value })
			this.selectedArray = []
			this.allCheck = false
		},
		level2Clicked(item) {
			this.$emit('chosen', { type: 'child', value: item.value, parent: item.parent })
		},
		childClicked(item) {
			this.clickType = 'child'
			this.$emit('accordion', item)
			if (this.splitSecondNav && this.childParent != -1 && this.secondNav != -1 && this.items[this.childParent].children[this.secondNav] != item) {
				this.selectedArray = []
			}
			if (item.hasOwnProperty('hasGrandChild')) {

				let indexOne = this.items.findIndex(x => x.value == item.parent)
				let indexTwo = this.items[indexOne].children.findIndex(y => y.value == item.value)
				this.secondNav = indexTwo
				this.childParent = indexOne
				this.currentTitle = item.label
				this.secondNavOpen = true
			}
		}
	},
	created() {
		// if (this.$route.meta.sidebarCollapsed) this.showMenu = false
		for (var i = 0; i < this.items.length; i++) {
			if (this.title == 'CAMS' || this.title === 'TC') {
				this.showAccordion.push({ showAccordion: true })
			} else {
				this.showAccordion.push({ showAccordion: false })
			}
		}
	},

	mounted() {
		if (window.innerWidth < 1205) {
			this.showMenu = false;
		}
	},
}

</script>

<style>
.remove-padding .ant-popover-inner-content {
	padding: 0 !important;
}
</style>

<style lang="scss">
.ant-tree li .ant-tree-node-content-wrapper.ant-tree-node-selected {
	color: var(--primary) !important;
	background-color: #f7f5f9 !important;
}

.ant-tree li .ant-tree-node-content-wrapper:hover {
	background-color: #f7f5f9 !important;
}

.ant-tree li .ant-tree-node-content-wrapper {
	padding: 0 5px !important;
}

.hideScroll .ps__rail-y {
	display: none;
}
</style>

<style lang="scss" scoped>
/* Hide scrollbar for Chrome, Safari and Opera */
.hidenscroll::-webkit-scrollbar {
	display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.hidenscroll {
	-ms-overflow-style: none;
	/* IE and Edge */
	scrollbar-width: none;
	/* Firefox */
}

.highlight {
	background-color: var(--off-white-dark);
	color: var(--orange);
}

.allModel {
	width: 100%;
	height: 50px;
	padding: 12px;
}

.allModel:hover {
	background-color: var(--off-white-light);
}

.first-bar-left {
	flex: 0 0 225px;
}

.second-bar-left {
	flex: 0 0 225px;
}

.instance {
	background-color: var(--light-bg-gray);
	width: 100%;
	height: 50px;
	padding: 12px;
	align-items: center;
}

.LeftNavBar {
	margin-top: -.1px;
	padding-top: .1px;
	padding-left: 0;
	width: 225px;
	transition: ease-out, width, 0.2s;
	align-items: stretch;
	position: relative;

	&:after {
		position: absolute;
		right: 0;
		top: 0;
		bottom: 0;
		width: 2px;
		border-right: 1px solid var(--light-purple);
		content: '';
		pointer-events: none;
	}

	&:not(.second-nav-open) {
		.second-bar-left {
			display: none;
		}
	}

	&.second-nav-open {
		width: 450px;
	}
}

.LeftNavBarMini {
	margin-top: -.1px;
	padding-top: .1px;
	padding-left: 0;
	width: 57px;
	transition: ease-out, width, 0.2s;
	align-items: stretch;

	&:not(.second-nav-open) {
		.second-bar-left {
			display: none;
		}
	}

	&.second-nav-open {
		width: 290px;
	}
}

.menu-button {
	font-size: 30px;
	color: var(--black);
}


ul {
	list-style: none;
	margin: 0;
	padding: 0;
}

.leftNavItemHover {
	cursor: pointer;

	&:hover {
		background-color: var(--off-white-light);
	}
}

.appCategory:hover {
	cursor: pointer;
	background-color: var(--off-white-light);
}

.appCategory:hover .more-option-icon {
	color: var(--black);
}

.appCategory:hover .tree-more-icon {
	color: var(--black);
}

.tree-more-icon {
	color: white;
}

.more-option-icon {
	position: absolute;
	display: inline-flex;
	color: white;
	right: 0%;
	width: 22px;
	height: 50px;
	align-items: center;
	justify-content: center;
}

.more-option-icon:hover {
	// background: rgba(100, 100, 100, 0.08);
	background: rgba(100, 100, 100, 0.08);
	content: '';
	color: var(--orange) !important;
}

.EditMenu {
	display: flex;
	height: 35px;
	align-items: center;
	width: 180px;
	cursor: pointer;
}

.EditMenu:hover {
	background-color: var(--off-white-light);
}

.catTitle {
	max-width: 224px;
	white-space: nowrap;
	text-overflow: ellipsis;
	overflow: hidden;
	padding-right: 21px;
}

.tree-folder {
	max-width: 175px;
	white-space: nowrap;
	text-overflow: ellipsis;
	overflow: hidden;
}

.selected-left-nav {
	background: var(--off-white-dark);
	color: var(--orange);
}

.leftnav_menu-holder {
	font-size: 26px;
	margin-top: 20px;
	padding-left: 20px;
	border-bottom: 1px solid var(--light-divider);
	margin-bottom: 5px;

	>i {
		cursor: pointer;
	}
}
</style>
