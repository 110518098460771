<template>
    <div>
		<a-modal :visible="previewModal.visible" :centered="true" :footer="null" class="no-padding" @cancel="closePreview" :zIndex="1001">
            <div style="padding:32px; background-color:#e8e7ed">
                <div style="background-color:#fff; padding:24px">
                    <div v-if="event.meetingSettings.emailNotification.logo != ''" style="padding-bottom:24px">
                        <img style="max-width:100px; max-height: 100px; object-fit: cover;" :src="event.meetingSettings.emailNotification.logo"></img>
                    </div>
					<template v-if="user">
						<h4>Meeting confirmation</h4>
						<div>Your appointment has been scheduled with {{`${user.firstName} ${user.lastName}`}}</div>
						<div class="mt-3">Email Address</div>
						<div>{{user.email}}</div>
						<div class="mt-3">Date / Time</div>
						<div>11/15/2022 05:00 PM (GMT-5:00) - Toronto</div>
						<div class="mt-3">Location</div>
						<div style="color:#1256cc; text-decoration: underline;">https://meet.google.com</div>
						<div class="mt-3">Note: if you need to make changes to your meeting</div>
					</template>

                    <div class="dF mt-3">
                        <div style="width:30%;">
                            <a-button size="large" style="background-color:#ECE9F1;">CANCEL</a-button>
                        </div>
                        <div style="width:70%;">
                            <a-button class="ml-4" type="primary" size="large">RESCHEDULE</a-button>
                        </div>
                    </div>
                </div>
            </div>
        </a-modal>
        <a-modal
            :visible="eventModal.type === 'Event' && eventModal.visible"
            @cancel="onClose"
            :footer="null"
            :bodyStyle="{padding: '30px', overflow: 'scroll'}"
			class="events-modal"
			width="80vw"
            centered
            >
            <BHLoading :show="loading" />
            <h5 class="mb-5">{{ editEvent && editEvent.event && editEvent.event.id ? 'Edit Event' : 'Add New Event' }}</h5>

            <div>
                <a-form-model ref="event" :model="event">
					<a-row :gutter="16">
                        <a-col :span="24">
                            <a-form-model-item label="Event Title" required prop="title" :rules="req('Please enter the title')">
                                <a-input placeholder="Title" v-model="event.title" size="large"/>
                            </a-form-model-item>
                        </a-col>
                    </a-row>
					<a-row :gutter="16">
                        <a-col :span="24">
                            <a-form-model-item label="Event Description" prop="description">
                                <a-textarea :auto-size="{ minRows: 5, maxRows: 5 }" placeholder="Description" v-model="event.description" size="large"/>
                            </a-form-model-item>
                        </a-col>
                    </a-row>
					<a-row :gutter="16">
                        <a-col :span="8">
                            <a-form-model-item label="Max invitees in a spot" prop="meetingSettings.spots" required :rules="req('Please enter the number of spots')">
								<a-tooltip overlayClassName="change-tooltip-color" style="position: absolute; top: -33px; left: 140px;">
									<template slot="title">
										The max number of invitees cannot exceed the max number of invitees that your provider allows per meeting (i.e., Gmail, Zoom)
									</template>
									<a-icon type="question-circle" style="color: var(--orange);" />
								</a-tooltip>
								<a-input-number :min="1" v-model="event.meetingSettings.spots" size="large" />
                            </a-form-model-item>
                        </a-col>
						<a-col :span="16">
							<a-form-model-item label="Apply Tags to Registrants" prop="tags">
								<a-select v-model="event.tags" mode="tags" style="width: 100%"
									placeholder="Interested Buyer/Senior/Have Pets..." size="large">
									<a-select-option v-for="tag in tags" :value="tag.name" :key="`SDFS${tag.id}`">
										{{ tag.name }}
									</a-select-option>
								</a-select>
                            </a-form-model-item>
						</a-col>
                    </a-row>
					<a-row :gutter="16">
						<a-col :span="8">
							<div class="w-3/4">
								<div style="font-weight: bold; font-size: 1rem;">Select your preferred event type<span style="color: var(--danger);">*</span></div>

								<a-select
									v-model="event.meetingSettings.preferredEventType"
									class="f1 w-full"
									style="margin-top: 1em;"
									size="large"
									@change="changeEventType"
								>
									<a-select-option value="physical">Physical Event</a-select-option>
									<a-select-option value="virtual">Virtual Event</a-select-option>
								</a-select>
							</div>
						</a-col>
						<a-col v-if="event.meetingSettings.preferredEventType == 'physical'" :span="16" class="mt-4">
							<div>
								<div style="font-size: 1rem;">Allow for 1-1 meetings?</div>

								<a-switch v-model="event.meetingSettings.allowOneToOne" />
							</div>
						</a-col>
					</a-row>

					<hr class="my-8"/>

					<a-row :gutter="16">
						<a-col :span="24">
							<div>
								<div style="font-weight: bold; font-size: 1rem;">When an event can be scheduled<span style="color: var(--danger);">*</span></div>
								<div>Set a period of rolling weeks or a custom ranges, people can select in your calendar</div>

								<a-radio-group v-model="event.meetingSettings.scheduledType" class="mt-4" @change="changeScheduleType">
									<a-radio value="rolling">
										Rolling Weeks
									</a-radio>
									<a-radio value="custom">
										Custom Date Range
									</a-radio>
								</a-radio-group>
								<div v-if="event.meetingSettings.scheduledType === 'custom' && event.meetingSettings.scheduleCustomDateRanges.length > 0" style="margin-top: 1em;">
									<div v-for="(date,index) in event.meetingSettings.scheduleCustomDateRanges" :key="date+index" class="available-times dF aC" style="gap: 25px">
										<a-date-picker
											v-model="event.meetingSettings.scheduleCustomDateRanges[index].startDate"
											:disabled-date="(e) => disabledStartDate(e, index)"
											valueFormat="YYYY-MM-DD"
											placeholder="Start Date"
											size="large"
										/>
										<a-date-picker
											v-model="event.meetingSettings.scheduleCustomDateRanges[index].endDate"
											:disabled-date="(e) => disabledEndDate(e, index)"
											valueFormat="YYYY-MM-DD"
											placeholder="End Date"
											size="large"
										/>
									</div>
								</div>

								<a-select
									v-else-if="event.meetingSettings.scheduledType === 'rolling'"
									v-model="event.meetingSettings.scheduleRollingWeekSlot"
									class="f1 w-full"
									style="margin-top: 1em;"
									size="large"
								>
									<a-select-option :value="10080">This week</a-select-option>
									<a-select-option :value="20160">Next week</a-select-option>
									<a-select-option :value="30240">3 weeks</a-select-option>
									<a-select-option :value="40320">4 weeks</a-select-option>
									<a-select-option :value="50400">5 weeks</a-select-option>
								</a-select>
							</div>


							<div class="mt-6">
								<a-form-model-item label="Event Organiser" prop="assignTo" :rules="req('Please select a user')">
									<a-tooltip overlayClassName="change-tooltip-color" style="position: absolute; top: -33px; left: 110px;">
										<template slot="title">
											For optimal user experience, make sure 1-1 meetings are using the same conferencing service as the event organiser (e.g., Gmail).
										</template>
										<a-icon type="question-circle" style="color: var(--orange);" />
									</a-tooltip>
										<a-tooltip v-if="notConnected" overlayClassName="change-tooltip-color" style="position: absolute; top: -33px; left: 132px;">
											<template slot="title">
												User is not connected to any conferencing service. Please connect to a conferencing service in Settings to continue.
											</template>
											<a-icon type="warning" style="color: var(--danger);" />
										</a-tooltip>
									<a-select
										v-model="event.assignTo"
										@change="selectedUser"
										:show-arrow="false"
										show-search
										placeholder="Type to search..."
										:not-found-content="null"
										size="large"
										:filter-option="filterOption"
										@search="searchUser">
										<a-select-option v-for="user in dataSource" :key="user.id" :value="user.id">
											{{ user.name }} {{ '(' + user.email + ')' }}
										</a-select-option>
									</a-select>
								</a-form-model-item>
							</div>


							<div class="mt-6" v-if="event.meetingSettings.preferredEventType == 'physical'">
								<a-form-model-item label="Event Location" prop="meetingSettings.location" :rules="req('Please enter a location')">
									<a-input
										v-model="event.meetingSettings.location"
										class="f1 w-full"
										size="large"
									>
									</a-input>
								</a-form-model-item>
							</div>


							<div class="mt-6">
								<div style="font-weight: bold; font-size: 1rem;">
									Available times<span style="color: var(--danger);">*</span>
								</div>
								<div>Choose the times of day you’ll run the event (for example 9:00 AM - 5:00 PM).</div>

								<div class="w-full mt-4 dF aC jSB">
									<div class="f1 available-times-container" style="display: grid; grid-template-columns: repeat(1, minmax(0, 1fr)); gap: 0.75rem; max-width: 230px; min-width: 125px;">
										<a-checkbox v-for="(day,dayI) in days" :key="day+dayI" :checked="event.meetingSettings.availableTimeSlots.dates.includes(day.id)" :value="day.id" @change="updateAvailableTimes">{{ day.label }}</a-checkbox>
									</div>
									<div class="f1 dF aC" style="max-width: 800px;">
										<div class="px-3">from</div>
										<a-time-picker v-model="event.meetingSettings.availableTimeSlots.from" use12-hours format="h:mm A" class="f1" size="large" :minute-step="15" />
										<div class="px-3">to</div>
										<a-time-picker v-model="event.meetingSettings.availableTimeSlots.to" use12-hours format="h:mm A" class="f1" size="large" :minute-step="15" />
									</div>
								</div>
							</div>

							<div class="mt-8">
								<a-form-model-item label="Event Duration" prop="meetingSettings.duration" :rules="req('Please select the duration of the event')">
									<a-select
										v-model="event.meetingSettings.duration"
										style="width: 100%;"
										size="large"
										placeholder="Please select"
									>
										<a-select-option :value="15">15 minutes</a-select-option>
										<a-select-option :value="30">30 minutes</a-select-option>
										<a-select-option :value="45">45 minutes</a-select-option>
										<a-select-option :value="60">1 hour</a-select-option>
										<a-select-option :value="75">1 hour 15 minutes</a-select-option>
										<a-select-option :value="90">1 hour 30 minutes</a-select-option>
										<a-select-option :value="105">1 hour 45 minutes</a-select-option>
										<a-select-option :value="120">2 hour</a-select-option>
									</a-select>
								</a-form-model-item>
							</div>

						</a-col>
					</a-row>
                    <a-row :gutter="16" class="mt-4 mb-3">
                        <a-col :span="24">
                            <a-form-model-item label="Setup a reminder" prop="reminderOn">
                                <a-select size="large" v-model="event.reminderOn">
                                    <a-select-option :value="15">15 Minutes</a-select-option>
                                    <a-select-option :value="30">30 Minutes</a-select-option>
                                    <a-select-option :value="60">1 Hour</a-select-option>
                                    <a-select-option :value="120">2 Hours</a-select-option>
                                    <a-select-option :value="360">6 Hours</a-select-option>
                                    <a-select-option :value="720">12 Hours</a-select-option>
                                    <a-select-option :value="1440">1 Day</a-select-option>
                                    <a-select-option :value="2880">2 Days</a-select-option>
                                    <a-select-option :value="10080">1 Week</a-select-option>
                                </a-select>
                            </a-form-model-item>
                        </a-col>
                    </a-row>
					<a-row :gutter="16" class="mt-4 mb-3">
						<a-col :span="24">
							<div style="font-weight: bold; font-size: 1rem;">Email notifications</div>
							<a-card class="w-full" style="margin-top: 1em;">
								<div>
									<div style="font-weight: bold;">Logo</div>
									<ImageBox v-if="eventModal.visible" class="mt-2" v-model="event.meetingSettings.emailNotification.logo" :removable="true" />
								</div>
								<div class="mt-3">
									<a-tooltip overlayClassName="change-tooltip-color">
										<template slot="title">
											The Sender Email Address identifies the address where the email is coming from.
											To add more addresses, go to <span><a
													:href="`https://email.bildhive.${$tld}/settings`" target="__blank"
													style="color: var(--orange);">Broadcast Email Builder</a></span> app.
										</template>
										<a-icon type="question-circle"
											style="line-height: 25px; position: absolute; margin-top: 1px; left: 220px; z-index: 2; color: var(--orange);" />
									</a-tooltip>
									<div style="font-weight: bold;">Select a Sender Email Address</div>
									<a-select v-model="event.meetingSettings.emailNotification.sender" class="mt-2" size="large" style="width: 100%">
										<a-select-option value="">
											Default (no-reply@mail.bildhive.com)
										</a-select-option>
										<a-select-option v-for="(email, emailI) in senders" :value="email.id"
											:key="email + emailI">
											{{ email.email }}
										</a-select-option>
									</a-select>
								</div>
								<div class="mt-5 dF aC" style="gap: 2em;">
									<a-button @click="showPreview" style="background-color:#ECE9F1;" size="large">PREVIEW</a-button>
									<a-tooltip slot="extra" overlayClassName="change-tooltip-color">
										<template slot="title">Test emails will not have active links. For testing purposes only.</template>
										<a-popover v-if="allowSendTest" trigger="click" :title="null" placement="bottomRight" :overlayStyle="{width:`400px`}">
											<div slot="content">
												<a-form>
													<a-form-item label="Enter Emails">
														<div class="dF aC">
															<!-- use when sending to multiple emails @change="handleTestEmails"  -->
															<a-select :token-separators="[',']" v-model="emails" placeholder="john@doe.com, jill@hive.com..." style="width:calc(100%-30px);">
																<a-select-option v-for="(user) in users" :value="user.email" :key="user.id">
																{{ user.firstName + ' ' + user.lastName  + ' (' + user.email + ')'}}
																</a-select-option>
															</a-select>
															<div style="width:30px;">
																<a-button :loading="sendingTest" type="primary" icon="mail" @click="sendTest"/>
															</div>
														</div>
													</a-form-item>
												</a-form>
											</div>
											<a-button style="background-color:#ECE9F1;" size="large">SEND TEST EMAIL</a-button>
										</a-popover>
									</a-tooltip>
								</div>
							</a-card>
						</a-col>
					</a-row>
                </a-form-model>
            </div>

            <div style="text-align: right;">
                <a-button @click="onClose" class="mt-3 cancel-button mr-3" size="large" style="width: 125px;">CANCEL</a-button>
                <a-button @click="onSubmit" style="width: 125px;" size="large" type="primary">{{ editEvent && editEvent.event && editEvent.event.id ? 'UPDATE' : 'CREATE' }}</a-button>
            </div>
        </a-modal>
    </div>
</template>

<script>
import BHLoading from 'bh-mod/components/common/Loading'
import ImageBox from '@/components/common/ImageBox'
import moment from 'moment'
import 'moment-timezone';

export default {
    components:{
        BHLoading,
		ImageBox,
        VNodes: {
        functional: true,
        render: (h, ctx) => ctx.props.vnodes,
        },
    },
    data() {
        return {
			sendingTest:false,
			emails:'',
            event:{
                title:'',
				description:'',
				tags:[],
				assignTo:'',
				participants:[],
				reminderOn:1440,
				meetingSettings:{
					emailNotification:{
						logo:'',
						sender:''
					},
					scheduleCustomDateRanges:[
						{
							startDate:null,
							endDate:null,
						}
					],
					scheduleRollingWeekSlot:null,
					scheduledType:'rolling',
					availableTimeSlots:{
						from:null,
						to:null,
						dates:[]
					},
					location:'',
					link:'',
					preferredEventType:'physical', //physical or virtual
					allowOneToOne:true,
					duration:null,
					spots:0
				}
            },
			previewModal:{
                visible:false
            },
			days:[
                {label:'Sunday', id:'Sunday'},
                {label:'Monday', id:'Monday'},
                {label:'Tuesday', id:'Tuesday'},
                {label:'Wednesday', id:'Wednesday'},
                {label:'Thursday', id:'Thursday'},
                {label:'Friday', id:'Friday'},
                {label:'Saturday', id:'Saturday'},
            ],
            notConnected:false,
            dataSource:[],
            loading:false,
			senders: [],
        }
    },
    watch:{
        visible:{
            handler(val) {
                if (!val) {
                    this.$store.commit('CLEAR_LEAD_ACTION')
                }
            }
        },
		editEvent(val){
			if (val && val.event && val.event.id){
				let event = JSON.parse(JSON.stringify(val.event));

				if (event.meetingSettings && event.meetingSettings.availableTimeSlots && event.meetingSettings.availableTimeSlots.from && event.meetingSettings.availableTimeSlots.to){
					event.meetingSettings.availableTimeSlots.from = moment(event.meetingSettings.availableTimeSlots.from, 'HH:mm')
					event.meetingSettings.availableTimeSlots.to = moment(event.meetingSettings.availableTimeSlots.to, 'HH:mm')
				}

				if (event.meetingSettings && event.meetingSettings.scheduleCustomDateRanges && event.meetingSettings.scheduleCustomDateRanges.length){
					event.meetingSettings.scheduleCustomDateRanges[0].endDate = moment(event.meetingSettings.scheduleCustomDateRanges[0].endDate).format('YYYY-MM-DD')
					event.meetingSettings.scheduleCustomDateRanges[0].startDate = moment(event.meetingSettings.scheduleCustomDateRanges[0].startDate).format('YYYY-MM-DD')
				}

				event.tags = event.tags.map(x => x.name)
				event.assignTo = event.assignTo.id

				this.users.forEach(x => {
                    x.name = x.firstName+ ' ' + x.lastName
                })
                this.dataSource = this.users

				this.event = event;
			}
		},
		'eventModal.visible':{
			handler(val){
				if (val){
					this.$api.get(`/senders/:instance`).then(({ data }) => {
						this.senders = data
					}).catch(err => {
						if (!err || !err.response || !err.response.status || err.response.status !== 400) {
							this.$message.error(this.$err(err))
						}
					})
				}
			}
		}
    },
    computed:{
		tags() {
			return Object.values(this.$store.state.contacts.tags)
		},
		timezone(){
			return this.$store.state.contacts.allSettings.user && this.$store.state.contacts.allSettings.user.options && this.$store.state.contacts.allSettings.user.options.regional && this.$store.state.contacts.allSettings.user.options.regional.timeZone && typeof this.$store.state.contacts.allSettings.user.options.regional.timeZone == 'string' ? this.$store.state.contacts.allSettings.user.options.regional.timeZone : 'America/New_York'
		},
        visible() {
            return this.$store.state.addNew.visible
        },
        changeTypeTime(){
            return this.$store.state.contacts.changeType.time
        },
        changeType(){
            return this.$store.state.contacts.changeType
        },
        eventModal(){
            return this.$store.state.addNew
        },
        opportunities(){
            return this.$store.state.contacts.opportunities
        },
        instance(){
            return this.$store.state.instance
        },
        contacts(){
            return Object.values(this.$store.state.contacts.allContacts)
        },
		user() {
            return this.$store.state.user.user
        },
        users(){
            return this.$store.state.contacts.users
        },
		editEvent(){
			return this.$store.state.contacts.editEvent
		},
		allowSendTest() {
            let vendors = this.$store.state.contacts.vendors

            if (vendors && ((vendors.gmail && vendors.gmail.active) || (vendors.office365 && vendors.office365.active) || (vendors.outlook && vendors.outlook.active))) return true
            else return false
        }
    },
    methods:{
		sendTest() {
            let sendObj = {
                mjml:'',
                subject:'',
                to:''
            }
            sendObj.mjml = `<mjml>
				<mj-body>
					<mj-section background-color="#e8e7ed" padding="30px 30px">
						${this.event.meetingSettings.emailNotification.logo && this.event.meetingSettings.emailNotification.logo != '' ?`
					<mj-column background-color="#FFF" width="100%">
						<mj-image padding-top="20px" align="left" width="100px" src="${this.event.meetingSettings.emailNotification.logo}"></mj-image>
					</mj-column>`:''}
					<mj-group background-color="#FFF" width="100%">
						<mj-column>
						<mj-text font-family="Arial" font-size="16px" >
							<h1 style="margin-bottom:0px">Meeting confirmation</h1>
						</mj-text>
						<mj-text line-height="1.5">
							<div>Your appointment has been scheduled with ${this.user.firstName} ${this.user.lastName}</div>
							<div style="padding-top:20px">Email Address</div>
							<div>${this.user.email}</div>
							<div style="padding-top:20px">Date / Time</div>
							<div>11/15/2022 05:00 PM (GMT-5:00) - Toronto</div>
							<div style="padding-top:20px">Location</div>
							<a style="color:#FF9027; text-decoration:underline">https://meet.google.com</a>
							<div style="padding-top:20px">Note: if you need to make changes to your meeting</div>
						</mj-text>
						</mj-column>
					</mj-group>

					<mj-column background-color="#FFF" width="30%" padding-bottom="20px">
						<mj-button align="left" background-color="#ECE9F1" color="#000">CANCEL</mj-button>
					</mj-column>
					<mj-column background-color="#FFF" width="70%" padding-bottom="20px">
						<mj-button align="left" background-color="#FF9027" color="#FFF">RESCHEDULE</mj-button>
					</mj-column>
					</mj-section>
				</mj-body>
				</mjml>`
            sendObj.subject = 'Meeting confirmation'
            sendObj.to = this.emails
			this.sendingTest = true

            this.$api.post(`/contacts/:instance/sendmail`, sendObj).then(({data}) => {
                this.emails = ''
                if (data && data.success) {
                    this.$notification['success']({
                        message: 'Email Sent',
                        description: 'Your email has been sent successfully.',
                        duration: 4
                    });
					this.sendingTest = false
                } else {
                    this.$notification['error']({
                        message: 'Error',
                        description: 'Something went wrong. Try again',
                        duration: 4
                    });
					this.sendingTest = false
                }
            })
        },
		showPreview() {
            this.previewModal.visible = true
        },
		closePreview() {
			this.previewModal.visible = false
		},
		changeScheduleType(e){
			if (e.target.value == 'custom'){
				if (this.event.meetingSettings && this.event.meetingSettings.scheduleCustomDateRanges && this.event.meetingSettings.scheduleCustomDateRanges.length == 0)
				this.event.meetingSettings.scheduleCustomDateRanges = [
					{
						startDate:null,
						endDate:null,
					}
				]
			}
		},
		changeEventType(){
			this.users.forEach(x => {
				x.name = x.firstName+ ' ' + x.lastName
			})
			this.dataSource = this.users
		},
		updateAvailableTimes(e){
			if (e.target.checked){
				if (!this.event.meetingSettings.availableTimeSlots.dates.includes(e.target.value)) this.event.meetingSettings.availableTimeSlots.dates.push(e.target.value)
			} else {
				if (this.event.meetingSettings.availableTimeSlots.dates.includes(e.target.value)) this.event.meetingSettings.availableTimeSlots.dates.splice(this.event.meetingSettings.availableTimeSlots.dates.indexOf(e.target.value), 1)
			}
			console.log('UPDATED DATES', this.event.meetingSettings.availableTimeSlots.dates)
		},
		tagsToIds(obj) {
			let tags = obj.tags || []
			obj.newTags = []
			let oldTags = []
			let self = this

			tags.forEach(tID => {
				if (self.tags.find(x => x.name === tID)) {
					let find = self.tags.find(x => x.name == tID)
					oldTags.push(find.id)
				}
				else obj.newTags.push(tID)
			})
			obj.tags = oldTags

			return obj
		},
		offsetDiffMs(type){
			let desiredTimezone = moment.tz(this.timezone);
			let desiredOffset = desiredTimezone.utcOffset();
			let currentOffset = new Date().getTimezoneOffset() * -1;
			let offsetDiff = 0
			let offsetDiffMs = 0
			if (currentOffset !== desiredOffset){
				if (type == 'set'){
					offsetDiff = currentOffset - desiredOffset;
				} else {
					offsetDiff = desiredOffset - currentOffset;
				}
				offsetDiffMs = offsetDiff * 60 * 1000;
			}
			return offsetDiffMs
		},
        async selectedUser(val){
            console.log('SELECTED A CONTACT', val)
            this.loading = true
			this.$api.get(`/contacts/:instance/user-connection-details/${val}`).then(({data}) => {
				console.log('User connection data coming back', data)
				if (!data.success){
					this.notConnected = true
					this.loading = false
					return
				}
			})
            this.loading = false
            this.notConnected = false
        },
        moment,
		filterOption(input, option) {
            return (
                option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
            );
        },
        req:msg=>({required:true,message:msg}),
        searchUser(input) {
            if(input && input.length>1) {
                this.users.forEach(x => {
                    x.name = x.firstName+ ' ' + x.lastName
                })
                this.dataSource = this.users.filter(({name}) => {
                    name = name.toLowerCase()
                    input = input.toLowerCase()
                    return name.includes(input)
                })
            } else {
                return this.dataSource = []
            }
        },
		addNewCustomRange(){
            this.event.meetingSettings.scheduleCustomDateRanges.push({
                startDate:null,
                endDate:null,
            })
        },
		disabledStartDate(startValue, index) {
			const endValue = this.event.meetingSettings.scheduleCustomDateRanges[index].endDate && moment(this.event.meetingSettings.scheduleCustomDateRanges[index].endDate).endOf('day');
			if (!startValue || !endValue) {
				return false;
			}
			return startValue.valueOf() > endValue.valueOf();
		},
		disabledEndDate(endValue, index) {
			const startValue = this.event.meetingSettings.scheduleCustomDateRanges[index].startDate && moment(this.event.meetingSettings.scheduleCustomDateRanges[index].startDate).startOf('day');
			if (!endValue || !startValue) {
				return false;
			}
			return startValue.valueOf() >= endValue.valueOf();
		},
		addNewTime(){
            let newTime = {
                id:Date.now(),
                from:null,
                to:null,
                type:''
            }
            this.event.meetingSettings.availableTimeSlots.push(newTime)
        },
		copyTime(time){
			const newTime = {
				...time,
                id:Date.now(),
            }
            this.event.meetingSettings.availableTimeSlots.push(newTime)
        },
        onClose(){
            console.log('ONCLOSE RAN')
            this.$refs.event.resetFields();
			this.event = {
                title:'',
				description:'',
				tags:[],
				assignTo:'',
				participants:[],
				reminderOn:1440,
				meetingSettings:{
					emailNotification:{
						logo:'',
						sender:''
					},
					scheduleCustomDateRanges:[
						{
							startDate:null,
							endDate:null,
						}
					],
					scheduleRollingWeekSlot:null,
					scheduledType:'rolling',
					availableTimeSlots:{
						from:null,
						to:null,
						dates:[]
					},
					location:'',
					link:'',
					preferredEventType:'physical', //physical or virtual
					allowOneToOne:true,
					duration:null,
					spots:0
				}
            },
            this.notConnected = false
            this.$store.commit('CLOSE_ADD_NEW')
			this.$store.commit('CLOSE_EDIT_EVENT')
        },
        onSubmit(){
			if (this.editEvent && this.editEvent.event && this.editEvent.event.id){
				console.log('Updating existing event...', this.editEvent.event.id);
				this.$refs.event.validate(async valid => {
					if (valid) {
						if (this.notConnected) return this.$message.error('The selected event organiser is not connected.');
						let event = JSON.parse(JSON.stringify(this.event))
						event = this.tagsToIds(event)

						let settings = event.meetingSettings

						if (settings.preferredEventType == 'virtual') {
							settings.location = ""
						}

						if(settings.scheduledType === 'custom'){
							settings.scheduleCustomDateRanges = settings.scheduleCustomDateRanges.filter(cd => cd.startDate && cd.endDate)
							if (settings.scheduleCustomDateRanges.length == 0) return this.$message.error('Please select at least one custom date range.')
						} else {
							settings.scheduleCustomDateRanges = [];
							if (!settings.scheduleRollingWeekSlot) return this.$message.error('Please select at least one rolling week slot.')
						}

						let invalid = false
						settings.availableTimeSlots.from = moment(settings.availableTimeSlots.from).format('HH:mm')
						settings.availableTimeSlots.to = moment(settings.availableTimeSlots.to).format('HH:mm')
						if (settings.availableTimeSlots.from == 'Invalid date' || settings.availableTimeSlots.to == 'Invalid date'){
							invalid = true
						}
						if (invalid) return this.$message.error('Please select valid time slots.')

						console.log('EVENT OBJECT', event)
						delete event.eventSlots

						this.loading = true
						await this.$api.put(`/event-groups/:instance/${event.id}`, event).then(({data}) => {
							this.$message.success('Event updated successfully!')
							this.$store.commit('UPDATE_EVENT', data)
							this.loading = false
							this.onClose()
						}).catch(err => {
							this.loading = false
						})
					} else {
						console.log('error submit!!');
						this.loading = false;
						return false;
					}
				});
			} else {
				console.log('Creating new event...')
				this.$refs.event.validate(async valid => {
					if (valid) {
						if (this.notConnected) return this.$message.error('The selected event organiser is not connected.');
						let event = JSON.parse(JSON.stringify(this.event))
						event = this.tagsToIds(event)

						let settings = event.meetingSettings

						if (settings.preferredEventType != 'physical') {
							settings.location = ""
						}

						if(settings.scheduledType === 'custom'){
							settings.scheduleCustomDateRanges = settings.scheduleCustomDateRanges.filter(cd => cd.startDate && cd.endDate)
							if (settings.scheduleCustomDateRanges.length == 0) return this.$message.error('Please select at least one custom date range.')
						} else {
							settings.scheduleCustomDateRanges = [];
							if (!settings.scheduleRollingWeekSlot) return this.$message.error('Please select at least one rolling week slot.')
						}

						let invalid = false
						settings.availableTimeSlots.from = moment(settings.availableTimeSlots.from).format('HH:mm')
						settings.availableTimeSlots.to = moment(settings.availableTimeSlots.to).format('HH:mm')
						if (settings.availableTimeSlots.from == 'Invalid date' || settings.availableTimeSlots.to == 'Invalid date'){
							invalid = true
						}
						if (invalid) return this.$message.error('Please select valid time slots.')

						console.log('EVENT OBJECT', event)
						this.loading = true
						await this.$api.post('/event-groups/:instance', event).then(({data}) => {
							this.$message.success('Event created successfully!')
							this.$store.commit('ADD_EVENT', data)
							this.loading = false
							this.onClose()
						}).catch(err => {
							this.loading = false
						})
					} else {
						console.log('error submit!!');
						this.loading = false;
						return false;
					}
				});
			}
        },
    },
}
</script>

<style lang="scss">
    .popoverStyle .ant-popover-inner-content {
        padding: 0;
        background-color: white;
        border-radius: 20px;
    }
	.events-modal .ant-modal {
		padding-bottom: 0;
	}
	.available-times-container .ant-checkbox-wrapper {
		margin: 0;
	}
</style>
