<template>
	<div class="footy">
		<div :class="$style.inner" class="dF jC">
			<router-link to="/privacy" class="px-4">Privacy</router-link>
			<router-link to="/terms" class="px-4">Terms</router-link>
		</div>
	</div>
</template>

<script>
import { mapState } from 'vuex'

export default {
	computed: mapState(['settings']),
}
</script>

<style lang="scss" module>
@import "./style.module.scss";
</style>
